import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { alert } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import CircleButton from './circleButton';

interface Props extends IntlProps {
    onUploaded: any;
    image: any;
    className?: string;
    imageWithoutHttp?: boolean;
}

interface State {
    imageB64: string | undefined;
}

/**
 * Component that represent an image uploader field
 */
class ImageUploader extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            imageB64: undefined,
        }
    }

    componentDidUpdate(prevProps: Props) {
        //get the base64 representation of the given image
        if (this.props.image !== prevProps.image && this.props.image instanceof File) {
            this.getBase64(this.props.image, (imageB64) => this.setState({ imageB64 }));
        }
    }

    /**
     * Get the image file as base64 string
     * @param img the image file
     * @param callback a callback function, take the base64 representation returns void
     */
    getBase64 = (img: File | Blob | undefined, callback: (v: any) => void): void => {
        if (img === undefined) callback(undefined);
        else {
            const reader = new FileReader();
            reader.addEventListener('load', () => callback(reader.result));
            reader.readAsDataURL(img as File | Blob);
        }
    }

    /**
     * Check that the file is an image (jpeg, png) and less than 2MB
     * @param file the uploaded file
     * @return true if the file is an image less than 2MB
     */
    checkImage = (file: File): boolean => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLessThan2MB = file.size / 1024 / 1024 <= 2;

        if (!isJpgOrPng) alert(this.props.intl.formatMessage({ defaultMessage: 'The file must be an image in JPG or PNG format.' }), "warning");

        if (!isLessThan2MB && this.props.imageWithoutHttp !== true) alert(this.props.intl.formatMessage({ defaultMessage: 'The file must be less than {size}MB.' }, { size: 2 }), "warning");

        if (this.props.imageWithoutHttp === true) {
            return isJpgOrPng;
        } else {
            return (isJpgOrPng && isLessThan2MB);
        }
    }

    /**
     * Call after that the image is uploaded
     * @param event event the triggered event
     */
    uploadImage = (event: any): void => {
        const { files } = event.target;
        if (files && files.length > 0 && this.checkImage(files[0])) {
            this.props.onUploaded(files[0]);
        }
    };

    /**
     * Called when the user drag the file over the component
     * @param event event the triggered event
     */
    onDragOver = (event: any): void => {
        event.preventDefault();
        event.stopPropagation();

        const div = document.getElementById("image-uploader")!;
        if (div.className.includes("image-uploader-drag")) return;
        div.className += " image-uploader-drag";
    }

    /**
     * Called when the user drop the file (Drag and Drop)
     * @param event event the triggered event
     */
    onDrop = (event: any): void => {
        const div = document.getElementById("image-uploader")!;
        div.className = div.className.replace("image-uploader-drag", "");
        this.uploadImage(event);
    }

    /**
     * Delete the uploaded image
     */
    onDelete = (event: React.MouseEvent): void => {
        event.stopPropagation();
        this.setState({ imageB64: undefined });
        this.props.onUploaded(undefined);
    }

    render() {
        return (
            <div id="image-uploader" onDragOver={this.onDragOver} onDrop={this.onDrop} className={`image-uploader-parent ${this.props.className ? this.props.className : this.props.className}`}>
                <input className="image-uploader-input" type="file" accept=".jpg,.jpeg,.png" onChange={this.uploadImage} />
                {
                    this.state.imageB64 || this.props.image ?
                        <div className="image-uploader-content">
                            <CircleButton className="image-uploader-delete" small title={this.props.intl.formatMessage({ defaultMessage: 'delete' })} icon={<DeleteOutlined />} onClick={this.onDelete} />
                            <img className="image-uploader-img" src={this.state.imageB64 ? this.state.imageB64 : this.props.image} alt="Uploaded file" />
                        </div>
                        :
                        <div className="image-uploader-content">
                            <UploadOutlined />
                            <p><FormattedMessage defaultMessage={'Add an image'} /></p>
                        </div>
                }
            </div>
        );
    }
}

export default injectIntl(ImageUploader);
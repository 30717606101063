import { DatePicker, Input, Tooltip } from "antd";
import moment from "moment";
import { memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelectable } from "react-selectable-box";
import getFormat from "../../../../utils/Lang";
import { Cell, ImportError } from "../../../../utils/types/generalTypes";
import FAIcon from "../../../common/FAIcon";

interface Props {
    cell: Cell;
    selected: boolean;
    rowSelected: boolean;
    error: ImportError;
    ignoreError: boolean;
    multipleCellSelected: boolean;
    index: number;
    rowIndex: number;
    setEdit: (edit: boolean) => void;
    onMove: (direction: string) => void;
    onChange: (value: string) => void;
    onPaste: (paste: React.ClipboardEvent<HTMLDivElement>) => void;
    onFocus: () => void;
    onMultipleCellDelete: () => void;
    onMultipleCellCopy: () => void;
    resetSelection: () => void;
}

const ImportCell = memo((props: Props) => {
    const intl = useIntl();
    const [edit, setEdit] = useState<boolean>(false);
    const [value, setValue] = useState<string>(props.cell.type === 'date' && props.cell.value.length === 0 ? moment().format('YYYY-MM-DD') : props.cell.value)
    const cellRef = useRef<HTMLDivElement | null>(null)

    const onInputExit = useCallback((pValue?: string) => {
        setEdit(false)
        props.setEdit(false);
        if (pValue !== undefined)
            props.onChange(pValue.trim());
        if (props.cell.value !== value)
            props.onChange(value.trim());
    }, [props, value]);

    const onCellKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
        const movingKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
        const enterKeys = ['Enter', 'NumpadEnter'];

        if (e.code === 'KeyC' && e.ctrlKey) {
            e.preventDefault();
            e.stopPropagation();
            if (navigator.clipboard)
                if (props.multipleCellSelected)
                    props.onMultipleCellCopy()
                else
                    navigator.clipboard.writeText(value)

            return;
        }

        if (movingKeys.includes(e.code)) {
            e.stopPropagation();
            e.preventDefault();
            props.resetSelection();
            props.onMove(e.code)
        }

        if (e.code === 'Delete') {
            if (props.multipleCellSelected) {
                props.onMultipleCellDelete()
            }
            else {
                setValue('')
                props.onChange?.('');
            }
        }

        if (e.code === 'Backspace') {
            setEdit(true)
            setValue('');
        }

        else if (/^[a-zA-Z]$/.test(e.key) || /^[0-9]$/.test(e.key) || enterKeys.includes(e.code)) {
            setEdit(true)
            if (!e.ctrlKey && !e.altKey && !enterKeys.includes(e.code))
                setValue('');
        }
    }, [props, value])

    const onInputKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        const movingKeys = ['ArrowUp', 'ArrowDown'];
        const enterKeys = ['Enter', 'NumpadEnter'];
        if (movingKeys.includes(e.code)) {
            props.onMove(e.code)
        }
        if (enterKeys.includes(e.code)) {
            props.onMove('ArrowDown')
        }
    }, [props])


    const formatValue = useMemo(() => {
        switch (props.cell.type) {
            case 'date':
                if (moment(props.cell.value).isValid()) {
                    return moment(props.cell.value).format(getFormat('DATE'))
                }
                if (props.cell.value !== '') {
                    return <FormattedMessage defaultMessage={'{value} (Invalid)'} values={{ value: props.cell.value }} />
                }
                else {
                    return props.cell.value
                }
            case 'percent':
                if (/^\d+(\.\d{1,2})?$/.test(props.cell.value)) {
                    return `${props.cell.value}%`
                }
                if (props.cell.value !== '') {
                    return <FormattedMessage defaultMessage={'{value} (Invalid)'} values={{ value: props.cell.value }} />
                }
                else {
                    return props.cell.value
                }
            case 'text':
            default:
                return props.cell.value
        }
    }, [props.cell.type, props.cell.value])

    useEffect(() => {
        setValue(props.cell.value ? props.cell.value : '')
    }, [props.cell.value])

    useEffect(() => {
        if (cellRef.current && props.selected)
            cellRef.current.focus()
    }, [props.selected])

    const hasError = useMemo(() => !props.ignoreError && (props.error.error || props.error.fromNetwork), [props.error.error, props.error.fromNetwork, props.ignoreError]);
    const hasWarn = useMemo(() => !props.ignoreError && props.cell.warn?.message, [props.cell.warn?.message, props.ignoreError]);

    const cellWidth = useMemo(() => (props.cell.width ? { width: props.cell.width, minWidth: props.cell.width } : {}), [props.cell.width])
    const cellEmptyError = useMemo(() => (props.cell.value === '' && props.error && !props.ignoreError ? { alignContent: 'center', justifyContent: 'center' } : {}), [props.cell.value, props.error, props.ignoreError])

    const usePlaceholder = useMemo(() =>  props.rowIndex == 0 && formatValue === '', [formatValue, props.rowIndex])

    return (
        <div
            key={`cell-${props.index}-${props.rowIndex}-${props.selected ? 'selected' : 'standby'}`}
        >
            {
                !edit ?
                    <SelectableItem value={`cell-${props.rowIndex}-${props.index}`} disabled={false}>
                        <div
                            ref={cellRef}
                            tabIndex={1}
                            className={`div-table-cell${hasWarn ? ' warn' : ''}${hasError ? ' error' : ''}${props.selected || props.rowSelected ? ' selected' : ''}${usePlaceholder ? ' placeholder' : ''}`}
                            onClick={(e) => {
                                props.resetSelection();
                                props.onFocus();
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            onFocus={(e) => {
                                props.onFocus();
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            onDoubleClick={() => {
                                setEdit(true);
                                props.setEdit(true)
                            }}
                            onKeyDown={onCellKeyDown}
                            onPaste={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setEdit(false)
                                props.onPaste(e);
                            }}
                            style={{ ...cellWidth, ...cellEmptyError }}
                        >
                            <div className={`div-table-cell-icon`} style={{ display: hasWarn || hasError ? 'block' : 'none' }}>
                                {
                                    hasError && props.error.icon &&
                                    <Tooltip title={props.error.message && intl.formatMessage(props.error.message.text, props.error.message.values)}>
                                        <FAIcon name={props.error.icon.name} prefix={props.error.icon.prefix} color='#df6f6f' />
                                    </Tooltip>
                                }
                                {
                                    hasWarn && props.cell.warn?.icon &&
                                    <Tooltip title={props.cell.warn.message && intl.formatMessage(props.cell.warn.message.text, props.cell.warn.message.values)}>
                                        <FAIcon name={props.cell.warn.icon.name} prefix={props.cell.warn.icon.prefix} color='#ff9f43' />
                                    </Tooltip>
                                }
                            </div>
                            {
                                usePlaceholder ?
                                    props.cell.placeholder
                                    :
                                    formatValue
                            }
                        </div>
                    </SelectableItem>
                    :
                    props.cell.type === 'date' ?
                        <DatePicker
                            tabIndex={1}
                            className={`div-table-cell`}
                            defaultValue={moment()}
                            onBlur={() => onInputExit()}
                            value={value === '' ? undefined : moment(value).isValid() ? moment(value) : moment()}
                            format={getFormat('DATE')}
                            defaultOpen
                            onChange={(e) => {
                                onInputExit(e ? e.format('YYYY-MM-DD') : '')
                                setValue(e ? e.format('YYYY-MM-DD') : '')
                            }}
                            allowClear
                            autoFocus
                            style={props.cell.width ? { width: props.cell.width, minWidth: props.cell.width } : {}}
                        />
                        :
                        <Input
                            tabIndex={1}
                            className={`div-table-cell`}
                            onBlur={() => onInputExit()}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            onKeyDown={onInputKeyDown}
                            onPaste={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setEdit(false)
                                props.onPaste(e);
                            }}
                            autoFocus
                            style={props.cell.width ? { width: props.cell.width, minWidth: props.cell.width } : {}}
                        />
            }
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        prevProps.selected === nextProps.selected
        && prevProps.error === nextProps.error
        && prevProps.rowSelected === nextProps.rowSelected
        && prevProps.ignoreError === nextProps.ignoreError
        && prevProps.cell.value === nextProps.cell.value
        && prevProps.cell.type === nextProps.cell.type
        && prevProps.cell.width === nextProps.cell.width
        && prevProps.cell.error.error === nextProps.cell.error.error
        && prevProps.cell.warn === nextProps.cell.warn
    )
})

ImportCell.displayName = 'brah';

export default ImportCell;

const SelectableItem = ({ children, value, disabled }: { children: ReactNode, value: string, disabled: boolean }) => {
    const { setNodeRef, isAdding, isSelected, isRemoving } = useSelectable({
        value,
        disabled,
        rule: 'collision'
    });

    return (
        <div ref={setNodeRef} className={`import-table-selectable-cell ${isAdding ? 'isAdding' : ''} ${isSelected ? 'isSelected' : ''} ${isRemoving ? 'isRemoving' : ''}`} style={{ height: 40 }}>
            {children}
        </div>
    );
};

/* ============ ARRAY UTILS =========== */
type comparerFunc<T> = (i: T) => boolean;
type lookupArray<T> = T[];

const defaultComparer = <T>(i: T): boolean => i !== undefined && i !== null;

export const getRandomItem = <T>(arr: T[]) => arr[Math.floor(Math.random() * arr.length)];

const filterByEmptying_core = <T>(arr: lookupArray<T>, comparer: comparerFunc<T> = defaultComparer, stopAfterLastMatch = false): [T[], T[]] => {
    let hasMatched = false;
    const notMatching: typeof arr = [], matches: typeof arr = [];

    for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        if (comparer(item)) {
            matches.push(item);
            hasMatched = true;
        }
        else {
            if (stopAfterLastMatch && hasMatched)
                return [matches, notMatching.concat(arr.slice(i + 1))];
            notMatching.push(item);
        }
    }

    return [matches, notMatching];
};

const filterByEmptyingBinary_core = <T>(arr: lookupArray<T>, comparer: comparerFunc<T>): [T[], T[]] => {
    const matches: typeof arr = [], notMatching: typeof arr = [];

    let firstMatchingIdx = -1, finalMatchingIdx = -1;
    const maxArrayLength = arr.length;

    for (let i = 0; i < maxArrayLength; i++) {
        if (comparer(arr[i])) {
            firstMatchingIdx = i;
            break;
        }
    }

    // No items match the comparer
    if (firstMatchingIdx < 0)
        return [[], arr];

    // looping + push is faster than a.concat(b.slice(X, Y))
    for (let i = 0; i < firstMatchingIdx; i++)
        notMatching.push(arr[i]);

    const n = arr.length;
    let left = firstMatchingIdx, right = n - 1;
    while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        if (comparer(arr[mid])) {
            finalMatchingIdx = mid;
            left = mid + 1;  // end is to the right
        } else {
            right = mid - 1; // end is to the left
        }
    }

    // Doing a loop + push is faster than a.concat(b.slice(X, Y))
    for (let i = finalMatchingIdx + 1; i < arr.length; i++)
        notMatching.push(arr[i]);

    // Doing a loop + push is faster than a.concat(b.slice(X, Y))
    for (let i = firstMatchingIdx; i <= finalMatchingIdx; i++)
        matches.push(arr[i]);

    return [matches, notMatching];
};

export const findByEmptying = <T>(arr: lookupArray<T>, comparer: comparerFunc<T> = defaultComparer): [(T | null), T[]] => {
    const notMatching: typeof arr = [];

    for (let i = 0; i < arr.length; i++) {
        const item = arr[i];

        if (comparer(item))
            return [item, notMatching.concat(arr.slice(i + 1))];
        else
            notMatching.push(item);
    }

    return [null, notMatching];
};

/**
 * Processes an array and splits it into matching and non-matching elements based on a comparer function.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} arr - The array to be processed.
 * @param {(i: T) => boolean} comparer - The function used to determine if an element matches.
 * @returns {[T[], T[]]} An array containing two arrays:
 * - The first array contains all elements that match the comparer function.
 * - The second array contains all elements that do not match the comparer function.
 */
export const filterUnsortedArrayByEmptying = <T>(arr: lookupArray<T>, comparer: comparerFunc<T> = defaultComparer) => {
    return filterByEmptying_core(arr, comparer);
};

/**
 * Processes an array and splits it into matching and non-matching elements based on a comparer function.
 * Requires all the expected match to be grouped in the array.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} arr - The array to be processed.
 * @param {(i: T) => boolean} comparer - The function used to determine if an element matches.
 * @param {boolean} binary - Search for the matches binarly. Much faster when expecting more than a few matches. Infinitly faster when expecting between more than a few to millions of results. 
 * @returns {[T[], T[]]} An array containing two arrays:
 * - The first array contains all elements that match the comparer function.
 * - The second array contains all elements that do not match the comparer function.
 */
export const filterSortedArrayByEmptying = <T>(arr: lookupArray<T>, comparer: comparerFunc<T> = defaultComparer, binary: boolean) => {
    if (binary)
        return filterByEmptyingBinary_core(arr, comparer);
    return filterByEmptying_core(arr, comparer, true);
};

/**
 * Searches binarly in a sorted array of numbers
 * @param array An array of numbers
 * @param target The number you want to find 
 * @returns The index of the target, or null if not found
 */
export const binaryFindIndex = (array: number[], target: number): number | null => {
    let left = 0;
    let right = array.length - 1;

    while (left <= right) {
        const mid = Math.floor((left + right) / 2);

        if (array[mid] === target)
            return mid;
        else if (array[mid] < target)
            left = mid + 1;
        else
            right = mid - 1;
    }

    return null;
}

/* =================================== */
import { PushpinOutlined, ReconciliationOutlined } from '@ant-design/icons';
import { Spin, Tabs } from 'antd';
import React, { Suspense } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Rules } from '../../rbacRules';
import { changeTypesOfDay, changeTypesOfDayOff, reset } from '../../store/actions/configurations';
import { CaseType } from '../../utils/constants';
import Network from '../../utils/network';
import { RouterProps } from '../../utils/types/generalTypes';
import { TypeOfDay } from '../../utils/types/planningTypes';
import { ApplicationState, ConfigurationsDispatchProps, StoreDispatch } from '../../utils/types/storeTypes';
import { alert, getCaseAndPlural } from '../../utils/utils';
import Can from '../common/general/can';
import Container from '../common/navigations/container';
//import Files from './files/files';
const Files = React.lazy(() => import('./files/files'));

import { FormattedMessage, injectIntl } from 'react-intl';
import { IntlProps } from '../app/LanguageProvider';
import ListProjects from './listProjects/listProjects';

//Keys for the different tabs
enum TabKeys {
    Projects = "p",
    Files = "f",
}

//Links for the different tabs
enum TabLink {
    Projects = "projects",
    Files = "files",
}
type ReduxProps = ConnectedProps<typeof connector>

interface IProps {

}

type Props = IProps & RouterProps & ConfigurationsDispatchProps & ReduxProps & IntlProps;

interface State {

}

/**
 * Component for the Configurations page
 */
class Project extends React.Component<Props, State> {

    componentDidMount() {
        // get types of day
        Network.getTypeOfDay().then(
            response => this.props.changeTypesOfDay!(response),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the types of day' }), "warning")
        );
        // get types of day
        Network.getTypeOfDayOff().then(
            response => this.props.changeTypesOfDayOff!(response),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the types of day' }), "warning")
        );

    }

    componentWillUnmount() {
        // reset all stored values
        // this.props.reset!();
    }

    /**
     * Get the current tab
     */
    getCurrentTab = (): string => {
        switch (this.props.match.params.tab) {
            case TabLink.Projects:
                return TabKeys.Projects;
            case TabLink.Files:
                return TabKeys.Files;
            default:
                return TabKeys.Projects;
        }
    }

    /**
     * Called when a tab is changed
     */
    onChangeTab = (key: string) => {
        let link = "";
        switch (key) {
            case TabKeys.Projects:
                link = TabLink.Projects;
                break;
            case TabKeys.Files:
                link = TabLink.Files;
                break;
            default:
                link = TabLink.Projects;
                break;
        }
        this.props.history.replace(`/${this.props.match.params.lang}/project/${link}`);
    }

    render() {
        const currentTab = this.getCurrentTab();
        return (
            <Can rule={Rules.Planning.Settings} redirect="/dashboard">
                <Container breadcrumb={[{ title: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, true, CaseType.FIRST_LETTER_UPPERCASE) : this.props.intl.formatMessage({ defaultMessage: 'Projects' }), link: "/project" }]}>
                    <Tabs animated activeKey={currentTab} onChange={this.onChangeTab}>
                        <Tabs.TabPane forceRender key={TabKeys.Projects} tab={
                            <span>
                                <PushpinOutlined />
                                {this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, true, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Projects'} />}
                                {/* <Tooltip title={"Types qui peuvent être attachés à des événements"}>
                                    <InfoCircleOutlined className="tab-tooltip-info" />
                                </Tooltip> */}
                            </span>
                        }>
                            <ListProjects />
                            {/* <TypesOfDayTab/> */}
                        </Tabs.TabPane>
                        <Tabs.TabPane forceRender key={TabKeys.Files} tab={
                            <span>
                                <ReconciliationOutlined />
                                <FormattedMessage defaultMessage={'Reports'} />
                                {/* <Tooltip title={"Règles applicables pour gérer le mission des types de jour."}>
                                    <InfoCircleOutlined className="tab-tooltip-info" />
                                </Tooltip> */}
                            </span>
                        }>
                            <Suspense fallback={<Spin size='large' spinning />}>
                                <Files />
                            </Suspense>
                            {/* <OccupancyRateTab /> */}
                        </Tabs.TabPane>

                    </Tabs>
                </Container>
            </Can>
        )
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTypesOfDay: (t: TypeOfDay[]) => dispatch(changeTypesOfDay(t)),
    changeTypesOfDayOff: (t: TypeOfDay[]) => dispatch(changeTypesOfDayOff(t)),
    reset: () => dispatch(reset()),
});
const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
});
const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(injectIntl(Project));
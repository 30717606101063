import moment from 'moment';
import isEqual from 'react-fast-compare';
import { StaffType } from '../../utils/types/generalTypes';
import { StatusType } from '../../utils/types/networkTypes';
import { ConfigurationsState, StoreAction } from '../../utils/types/storeTypes';

export const CONFIGURATIONS_INIT_STATE: ConfigurationsState = {
    occupancyRates: [],
    typesOfDay: [],
    typesOfDayOff: [],
    staffTypes: { status: StatusType.NONE },
    departments: {
        updated: undefined,
        loading: false,
        data: []
    },
    typesOfContract: {
        updated: undefined,
        data: []
    },
    countries: {
        updated: undefined,
        data: []
    },
    project: [],
    appVersion: {
        last_updated: undefined,
        data: {
            name: process.env.REACT_APP_NAME ? process.env.REACT_APP_NAME : "sunkhronos_admin",
            version: process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : "1.0.0",
            modified: moment(),
            created: moment()
        }
    },
    vehicles: {
        updated: undefined,
        data: []
    },
};
export const CHANGE_APP_VERSION = ('CHANGE_APP_VERSION');
export const CHANGE_PERMANENT_NOTIF_API = ('CHANGE_PERMANENT_NOTIF_API');

export const CHANGE_OCCUPANCY_RATES = ('CHANGE_OCCUPANCY_RATES');
export const CHANGE_TYPES_OF_DAY = ('CHANGE_TYPES_OF_DAY');
export const CHANGE_TYPES_OF_CONTRACT = ('CHANGE_TYPES_OF_CONTRACT');
export const CHANGE_DEPARTMENTS = ('CHANGE_DEPARTMENTS');
export const LOADING_DEPARTMENTS = ('LOADING_DEPARTMENTS');
export const CHANGE_TYPES_OF_VEHICLES = ('CHANGE_TYPES_OF_VEHICLES');
export const CHANGE_TYPES_OF_DAY_OFF = ('CHANGE_TYPES_OF_DAY_OFF');
export const CHANGE_PROJECT = ('CHANGE_PROJECT');
export const CHANGE_COUNTRIES = ('CHANGE_COUNTRIES');
export const CHANGE_STAFFTYPES = ('CHANGE_STAFFTYPES');

export const RESET = ('RESET_CONFIGURATION');

const configurations = (state: ConfigurationsState = CONFIGURATIONS_INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case CHANGE_PERMANENT_NOTIF_API:
            return {
                ...state,
                permanentNotifApi: action.data
            };
        case CHANGE_APP_VERSION:
            return {
                ...state,
                appVersion: { last_updated: moment(), data: action.data }
            };
        case CHANGE_OCCUPANCY_RATES:
            return {
                ...state,
                occupancyRates: action.data,
            };
        case CHANGE_TYPES_OF_DAY:
            return {
                ...state,
                typesOfDay: action.data,
            };
        case CHANGE_TYPES_OF_CONTRACT:
            if (!action.forceReload && isEqual(state.typesOfContract.data, action.data)) {
                return {
                    ...state,
                    typesOfContract: {
                        ...state.typesOfContract,
                        updated: state.typesOfContract.updated ?? moment()
                    }
                };
            } else {
                return {
                    ...state,
                    typesOfContract: {
                        updated: moment(),
                        data: action.data
                    }
                };
            }
        case CHANGE_COUNTRIES:
            if (!action.forceReload && isEqual(state.countries.data, action.data)) {
                return {
                    ...state,
                    countries: {
                        updated: state.countries.updated ?? moment(),
                        data: action.data
                    }
                };
            } else {
                return {
                    ...state,
                    countries: {
                        updated: moment(),
                        data: action.data
                    }
                };
            }
        case CHANGE_DEPARTMENTS:
            if (!action.forceReload && isEqual(state.departments.data, action.data)) {
                return {
                    ...state,
                    departments: {
                        updated: state.departments.updated ?? moment(),
                        loading: false,
                        data: action.data
                    }
                };
            } else {
                return {
                    ...state,
                    departments: {
                        updated: moment(),
                        loading: false,
                        data: action.data
                    }
                };
            }
        case LOADING_DEPARTMENTS:
            return {
                ...state,
                departments: {
                    ...state.departments,
                    loading: action.data
                }
            };
        case CHANGE_TYPES_OF_VEHICLES:
            if (!action.forceReload && isEqual(state.vehicles.data, action.data)) {
                return {
                    ...state,
                    vehicles: {
                        ...state.vehicles,
                        updated: state.vehicles.updated ?? moment()
                    }
                };
            } else {
                return {
                    ...state,
                    vehicles: {
                        updated: moment(),
                        data: action.data
                    }
                };
            }
        case CHANGE_TYPES_OF_DAY_OFF:
            return {
                ...state,
                typesOfDayOff: action.data,
            };
        case CHANGE_PROJECT:
            return {
                ...state,
                project: action.data,
            };
        case CHANGE_STAFFTYPES:
            return {
                ...state,
                staffTypes: {
                    status: StatusType.FULFILLED,
                    updated: moment().format("YYYY-MM-DD HH:mm:ss"),
                    data: action.data as StaffType[]
                }
            };
        case RESET:
            return CONFIGURATIONS_INIT_STATE;
        default:
            return state;
    }
};

export default configurations;
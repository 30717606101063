import { PlusOutlined, WarningOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps, Popconfirm } from "antd";
import moment, { Moment } from "moment";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import getFormat from "../../../../utils/Lang";
import { PlanningCell } from "../../../../utils/types/generalTypes";
import EventPerf, { onEventActionCallback } from './EventPerf';

interface Props {
    userId: number;
    data: PlanningCell;
    day: Moment;
    canEdit: boolean;
    dayStr: string;
    isBusinessDay: boolean;
    selectedEventIds: number[];
    forceCallback?: (exec: boolean) => void;
    onCreateEvent: (userId: number, dayStr: string) => void;
    onCopySelectedEvents: (userId: number, dayStr: string) => void;
    onOpenEvent: (eventId: number) => void;
    onCopyEvent: onEventActionCallback<true>;
    onDeleteEvent: (eventId: number, userId: number, dayStr: string) => any;
    onCutEvent: onEventActionCallback<true>;
    onSelectEvent: onEventActionCallback<true>;
    onConfirmEvent: (userId: number, eventId: number) => void;
    onEditEvent: (eventId: number) => void | Promise<void>;
    onPaste: (userId: number, dayStr: string) => void | Promise<void>;
}

const Cell = (props: Props) => {
    const { dayStr, userId } = props;
    const eventsCount = useMemo(() => props.data.events.length, [props.data.events.length]);

    const onCreateEvent = useCallback(() => {
        props.onCreateEvent(userId, dayStr);
    }, [userId, dayStr, props]);

    const menuItems: MenuProps['items'] = useMemo(() => ([
        {
            label: <FormattedMessage defaultMessage={'Add an event'} />,
            key: `mp-menu-add-u${userId}-empty`,
            icon: <PlusOutlined />,
            onClick: onCreateEvent,
            style: { color: 'var(--positive-color)' },
        },
    ]), [userId, onCreateEvent]);

    const availabilitiesClassname = useMemo(() => props.data.unavailabilities ? props.data.unavailabilities.type === 'P' ? '__mp-cell-partially' : '__mp-cell-blocked' : '', [props.data.unavailabilities]);

    const content = (
        <div>
            {props.data.events.map(e => (
                <EventPerf
                    key={e.id}
                    event={e}
                    day={props.day}
                    dayStr={props.dayStr}
                    selected={props.selectedEventIds.includes(e.id)}
                    hasSelectedEventsOnCurrentDay={props.selectedEventIds.length ? true : false}
                    hasSelectedEventsonAnyDay={false}
                    isCopied={false}
                    isCutted={false}
                    canEdit={props.canEdit}
                    onOpen={props.onOpenEvent}
                    onCopy={props.onCopyEvent}
                    onDelete={props.onDeleteEvent}
                    onCopySelectedEvents={props.onCopySelectedEvents}
                    onCut={props.onCutEvent}
                    onConfirm={props.onConfirmEvent}
                    onEdit={props.onEditEvent}
                    onCreate={props.onCreateEvent}
                    onSelect={props.onSelectEvent}
                />
            ))}
        </div>
    );

    const tooltip = useMemo(() => {
        const ranges = props.data.unavailabilities?.type === 'P' ? props.data.unavailabilities.ranges : undefined;
        if (ranges) {
            const strs = [];
            for (const range of ranges) {
                strs.push(`${moment(range[0], "HHmmss").format(getFormat('TIME_SHORT'))} - ${moment(range[1], "HHmmss").format(getFormat('TIME_SHORT'))}`);
            }
            return strs.join(' | ');
        }
        return undefined;
    }, [props]);
    
    const cellFiller = useMemo(() => (eventsCount > 0
        ?
        <div className={`__mp-fill-td ${availabilitiesClassname} ${props.canEdit ? '' : 'disable'}`} data-toggle="tooltip" data-html="true" title={tooltip} onDoubleClick={onCreateEvent} />
        :
        <Dropdown trigger={['contextMenu']}
            menu={{ items: menuItems }}
            className={`__mp-fill-td ${availabilitiesClassname} ${props.canEdit ? '' : 'disable'}`}
        ><div onDoubleClick={onCreateEvent} title={tooltip} data-toggle="tooltip" data-html="true" /></Dropdown>
    ), [eventsCount, availabilitiesClassname, onCreateEvent, menuItems, props.canEdit, tooltip]);

    const paste = useCallback(() => {
        props.canEdit && props.onPaste(userId, dayStr);
    }, [props, userId, dayStr]);

    let cellContent = (
        <>
            {cellFiller}
            {content}
            <div className={`__mp-paste  ${props.canEdit ? '' : 'disable'}`} onClick={paste} />
        </>
    );

    if (props.forceCallback) {
        cellContent = (
            <Popconfirm
                open={true}
                title={
                    <>
                        <p style={{ fontWeight: 'bolder' }}><FormattedMessage defaultMessage={'Force copy'} /></p>
                        <p><FormattedMessage defaultMessage={"Record the event despite the selected user's lack of availability."} /></p>
                    </>
                }
                okText={<FormattedMessage defaultMessage={'Force copy'} />}
                cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                okButtonProps={{ type: 'link' }}
                cancelButtonProps={{ type: 'link' }}
                icon={<WarningOutlined style={{ color: '#ff2626' }} />}
                placement={'bottom'}
                onConfirm={(e) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                    props.forceCallback && props.forceCallback(true);
                }}
                onCancel={() => props.forceCallback && props.forceCallback(false)}>
                {cellContent}
            </Popconfirm>
        );
    }

    return (
        <>{cellContent}</>
    );
};

Cell.displayName = "cell";

export default Cell;
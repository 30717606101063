import { CheckOutlined, CloseOutlined, InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Container } from "@mui/material";
import { Avatar, Col, message, Popover, Row, Space, Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import isEqual from "react-fast-compare";
import { TbClockCheck } from "react-icons/tb";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import getFormat from "../../../utils/Lang";
import Network from "../../../utils/network";
import { PlanningOvertime } from "../../../utils/types/planningTypes";
import { ApplicationState } from "../../../utils/types/storeTypes";
import { showNotification } from "../../../utils/utils";
import CircleButton from "../../common/fields/circleButton";
import Anticon from "../../common/general/anticon";
import ControlTime, { DataByDate } from "../../common/general/controlTime";
import Event from '../../common/general/event';



export const OvertimesControl = () => {
    const loadingOvertime = useRef<'idle' | 'loading' | 'success' | 'error'>('idle');
    //const [ overtimes, setOvertimes ] = useState<PlanningOvertime[]>([])
    const [overtimes, setOvertimes] = useState<PlanningOvertime[]>([]);
    const [overtimesEditRights, setOvertimesEditRights] = useState<number[]>([]);
    const [selectedDay, setSelectedDay] = useState<Moment>(moment());
    const prevSelectedDates = useRef<{ startDate: Moment, endDate: Moment; } | undefined>(undefined);
    const [selectedDates, setSelectedDates] = useState<{ startDate: Moment, endDate: Moment; }>({ startDate: moment().startOf('week').startOf('day'), endDate: moment().endOf('week').endOf('day') });
    const [display, setDisplay] = useState<'day' | 'week' | 'month' | 'year'>('day');
    const [overtimesByDate, setOvertimesByDates] = useState<DataByDate[]>([]);
    const isSmartphone = useSelector((state: ApplicationState) => state.window.isSmartphone);
    const intl = useIntl();

    const loadOvertimes = useCallback((force = false) => {
        if (force || loadingOvertime.current !== 'loading') {
            loadingOvertime.current = ('loading');
            Network.getOvertimesNotConfirmed().then(
                (response: { error: boolean, message: string, data: PlanningOvertime[], rights: number[]; }) => {
                    if (response.error === false) {
                        loadingOvertime.current = ('success');
                        setOvertimes(response.data);
                        setOvertimesEditRights(response.rights);
                    } else {
                        loadingOvertime.current = ('error');
                        prevSelectedDates.current = undefined;
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the overtimes' }), "warning");
                    }

                },
                () => {
                    loadingOvertime.current = ('error');
                    prevSelectedDates.current = undefined;
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the overtimes' }), "warning");
                }

            );
        }
    }, [intl]);

    const overtimesFiltered = useMemo(() => {
        console.log("DISPLAY", display);
        if (display === 'day')
            return overtimes.filter(o => moment(o.startDate).isSame(selectedDay, 'day'));
        else if (display === 'week')
            return overtimes.filter(o => moment(o.startDate).isBetween(selectedDates.startDate, selectedDates.endDate));
        else {
            return overtimes.filter(o => moment(o.startDate).isSame(selectedDay, 'year'));
        }

    }, [overtimes, selectedDay, selectedDates, display]);

    useEffect(() => {
        const dataByDate: DataByDate[] = [];

        for (let day = selectedDates.startDate.clone(); day.isSameOrBefore(selectedDates.endDate); day.add(1, 'day')) {
            // const isConfirmedNr = overtimes.reduce((i, overtime) => i = i + (moment(overtime.startDate).isSame(day, 'day') && overtime.isConfirmed ? 1 : 0), 0);
            // const objectsNr = overtimes.filter(overtime => moment(overtime.startDate).isSame(day, 'day')).length;
            // const filteredObjectsNr = overtimes.filter(overtime => moment(overtime.startDate).isSame(day, 'day')).length;
            const nbUnprocessedEvents = overtimes.filter(overtime => moment(overtime.startDate).isSame(day, 'day')).length;
            const index = dataByDate.findIndex(dBD => dBD.date.isSame(day, 'day'));
            if (index === -1) {
                dataByDate.push({
                    date: day.clone(),
                    nbUnprocessedEvents
                });
            } else {
                dataByDate[index] = {
                    date: day.clone(),
                    nbUnprocessedEvents: (nbUnprocessedEvents + dataByDate[index].nbUnprocessedEvents)
                };
            }

        }
        setOvertimesByDates(dataByDate);

    }, [overtimes, selectedDates]);

    useEffect(() => {
        if (loadingOvertime.current !== 'loading' && !isEqual(selectedDates, prevSelectedDates.current)) {
            prevSelectedDates.current = selectedDates;
            loadOvertimes();
        }
    }, [selectedDates, loadOvertimes]);

    const overtimeValidate = useCallback((overtimeId: number) => {
        const newOvertimes = overtimes?.filter(o => o.id !== overtimeId);

        Network.validateOvertimeNotConfirmed(overtimeId).then(
            response => {
                if (response['status'] && response['status'] === "success") {
                    message.success("Overtime validé.");
                    setOvertimes(newOvertimes);
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while validating the overtimes' }), "warning");
                }
            },
            () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while validating the overtimes' }), "warning")
        );
    }, [intl, overtimes]);

    const overtimeRefuse = useCallback((overtimeId: number) => {
        const newOvertimes = overtimes?.filter(o => o.id !== overtimeId);

        Network.refuseOvertimeNotConfirmed(overtimeId).then(
            response => {
                if (response['status'] && response['status'] === "success") {
                    message.success("Overtime refusé.");
                    setOvertimes(newOvertimes);
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while validating the overtimes' }), "warning");
                }
            },
            () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while validating the overtimes' }), "warning")
        );
    }, [intl, overtimes]);

    const RenderDuration = useCallback((props: { record: PlanningOvertime; }): JSX.Element => {
        const { record } = props;
        const diff = moment(record.endDate).diff(moment(record.startDate));
        const duration = moment.duration(diff);

        return (
            <Tooltip title={
                <div>
                    <p><FormattedMessage defaultMessage={'Overtime duration'} /></p>
                    <p><FormattedMessage defaultMessage={' - In red: positive overtime'} /></p>
                    <p><FormattedMessage defaultMessage={' - In blue: negative overtime'} /></p>
                </div>
            }>
                {diff === 0 ?
                    <Tag color='green' style={{ fontSize: 15 }}>{moment.utc(duration.asMilliseconds()).format(getFormat('TIME_SHORT'))}</Tag>
                    :
                    record.isNegative ?
                        <Tag color='blue' style={{ fontSize: 15 }}>{`- ${moment.utc(duration.asMilliseconds()).format(getFormat('TIME_SHORT'))}`}</Tag>
                        :
                        <Tag color='volcano' style={{ fontSize: 15 }}>{`+ ${moment.utc(duration.asMilliseconds()).format(getFormat('TIME_SHORT'))}`}</Tag>
                }
            </Tooltip>
        );
    }, []);

    const overtimesColumns = useMemo((): ColumnsType<PlanningOvertime> => [
        {
            title: <FormattedMessage defaultMessage={'Employee'} />,
            key: 'employee',
            onFilter: (value, record) => {
                const name = record.firstName + " " + record.lastName;
                return name
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            sorter: (a: PlanningOvertime, b: PlanningOvertime) => {
                const aName = a.firstName + " " + a.lastName;
                const bName = b.firstName + " " + b.lastName;
                return !aName ? -1 : !bName ? 1 : aName < bName ? -1 : 1;
            },
            className: isSmartphone ? 'timeclock-control-table-fixed-even even __min-width-100' : 'timeclock-control-table-fixed-even timeclock-user __min-width-220',
            render: (record: PlanningOvertime) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {
                        isSmartphone ?
                            <Popover content={record.firstName + " " + record.lastName}>
                                <Avatar key={`avatar-object-${record.userId}`} size={50} icon={<UserOutlined />} src={`${record.userAvatar != null ? record.userAvatar : ''}`} style={{ backgroundColor: 'var(--primary-color)' }} />
                            </Popover>
                            :
                            <>
                                <Avatar key={`avatar-object-${record.userId}`} size={50} icon={<UserOutlined />} src={`${record.userAvatar != null ? record.userAvatar : ''}`} style={{ backgroundColor: 'var(--primary-color)' }} />
                                <span style={{ marginLeft: '10px' }}>
                                    {record.firstName + " " + record.lastName}
                                </span>
                            </>
                    }
                </div>
            )
        },
        {
            title: <FormattedMessage defaultMessage={'Event'} />,
            className: `timeclock-control-table-fixed-odd ${isSmartphone ? '__width_180' : '__width_180'}`,
            key: 'event',
            onFilter: (value, record) => {
                return record.title!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            sorter: (a: PlanningOvertime, b: PlanningOvertime) => !a.title ? -1 : !b.title ? 1 : a.title < b.title ? -1 : 1,
            render: (record: PlanningOvertime) => record.event && <Event key={`event-${record.event.id}`} event={record.event} displayIcons displayOverlay />
        },
        ...(!(display === 'day') || isSmartphone ?
            [
                {
                    title: <FormattedMessage defaultMessage={'Date'} />,
                    className: `timeclock-control-table-fixed-even ${isSmartphone ? '__width_160' : '__width_270'}`,
                    key: 'date',
                    onFilter: (value: any, record: any) => {
                        return record.title!
                            .toString()
                            .toLowerCase()
                            .includes(value.toString().toLowerCase());
                    },
                    sorter: (a: PlanningOvertime, b: PlanningOvertime) => !a.title ? -1 : !b.title ? 1 : a.title < b.title ? -1 : 1,
                    render: (record: PlanningOvertime) => {
                        const dateFormat = isSmartphone ? getFormat('DATE_SHORT') : getFormat('DATE');
                        return (
                            record.event && <Container style={{ paddingLeft: 20, paddingRight: 20 }}>
                                <Row gutter={[10, 10]}>
                                    <Col xs={{ span: 24 }} md={{ span: isSmartphone ? 24 : 10 }}>
                                        {moment(record.event.startDate).format(dateFormat)}
                                    </Col>
                                    {
                                        !isSmartphone &&
                                        <Col md={{ span: isSmartphone ? 0 : 4 }}>
                                            {'-'}
                                        </Col>
                                    }
                                    <Col xs={{ span: 24 }} md={{ span: isSmartphone ? 24 : 10 }}>
                                        {moment(record.event.endDate).format(dateFormat)}
                                    </Col>
                                </Row>
                            </Container>
                        );
                    }
                }
            ]
            :
            []
        ),
        {
            title: <FormattedMessage defaultMessage={'Hours'} />,
            key: 'hours',
            className: `timeclock-control-table-fixed-even ${isSmartphone ? '__width_120' : '__width_200'}`,
            render: (record: PlanningOvertime) => {
                return (

                    record.event && <Container style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <Row gutter={[10, 10]}>
                            <Col xs={{ span: 24 }} md={{ span: isSmartphone ? 24 : 10 }}>
                                {moment(record.event.startDate).format(getFormat('TIME_SHORT'))}
                            </Col>
                            {
                                !isSmartphone &&
                                <Col md={{ span: isSmartphone ? 0 : 4 }}>
                                    {'-'}
                                </Col>
                            }
                            <Col xs={{ span: 24 }} md={{ span: isSmartphone ? 24 : 10 }}>
                                {moment(record.event.endDate).format(getFormat('TIME_SHORT'))}
                            </Col>
                        </Row>
                    </Container>
                );
            }

        },
        {
            title: (
                <span><FormattedMessage defaultMessage={'Duration'} />
                    <Tooltip title={
                        <div>
                            <p><FormattedMessage defaultMessage={'Event duration'} /></p>
                            <p><FormattedMessage defaultMessage={'The time difference is displayed below the planned time:'} /></p>
                            <p><FormattedMessage defaultMessage={' - Green: shorter duration'} /></p>
                            <p><FormattedMessage defaultMessage={' - Red: longer duration'} /></p>
                            <p><FormattedMessage defaultMessage={' - Blue: Timeclock in progress'} /></p>
                        </div>
                    }>
                        <InfoCircleOutlined className="tab-tooltip-info" />
                    </Tooltip>
                </span>
            ),
            sorter: (a: PlanningOvertime, b: PlanningOvertime) => {
                const aStartDate = a.startDate;
                const bStartDate = b.startDate;
                return !aStartDate ? -1 : !bStartDate ? 1 : aStartDate < bStartDate ? -1 : 1;
            },
            key: 'totalDuration',
            className: `timeclock-control-table-fixed-even ${isSmartphone ? '__width_120' : '__width_120'}`,
            render: (record: PlanningOvertime) => <RenderDuration record={record} />

        },
        {
            title: <FormattedMessage defaultMessage={'Reason'} />,
            key: 'title',
            onFilter: (value, record) => {
                return record.title!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            sorter: (a: PlanningOvertime, b: PlanningOvertime) => {
                const aName = a.title;
                const bName = b.title;
                return !aName ? -1 : !bName ? 1 : aName < bName ? -1 : 1;
            },
            className: isSmartphone ? 'timeclock-control-table-fixed-even even __min-width-100' : 'timeclock-control-table-fixed-even timeclock-user __min-width-220',
            render: (record: PlanningOvertime) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {

                        <span>
                            {record.title}
                        </span>
                    }
                </div>
            )
        },
        {
            title: (
                <span><FormattedMessage defaultMessage={'Actions'} />
                    <Tooltip title={
                        <div>
                            <p><FormattedMessage defaultMessage={'Confirm, modify or reject'} /></p>
                            <p><FormattedMessage defaultMessage={'Unavailable actions:'} /></p>
                            <p><FormattedMessage defaultMessage={' - Timeclock in progress'} /></p>
                            <p><FormattedMessage defaultMessage={' - Insufficient rights'} /></p>
                        </div>
                    }>
                        <InfoCircleOutlined className="tab-tooltip-info" />
                    </Tooltip>
                </span>
            ),
            key: 'modifier',
            className: `timeclock-control-table-fixed-even ${isSmartphone ? '__width_110' : '__width_110'}`,
            render: (record: PlanningOvertime) => <Space>
                {record.id !== undefined && overtimesEditRights.includes(record.id) && <CircleButton onClick={() => overtimeValidate(record.id!)} title={intl.formatMessage({ defaultMessage: 'Validate' })} icon={<CheckOutlined />} />}
                {record.id !== undefined && overtimesEditRights.includes(record.id) && <CircleButton onClick={() => overtimeRefuse(record.id!)} title={intl.formatMessage({ defaultMessage: 'Reject' })} icon={<CloseOutlined />} />}
            </Space>


        }

    ], [isSmartphone, display, RenderDuration, overtimesEditRights, intl, overtimeValidate, overtimeRefuse]);
    return (
        <>
            <ControlTime
                tableRender={<Table
                    columns={overtimesColumns}
                    dataSource={overtimesFiltered}
                    rowKey={(o: PlanningOvertime) => `dashboard-user-overtimes-${o.id}`}
                    scroll={{ x: true, y: undefined }}
                    pagination={{
                        defaultPageSize: 8,
                        pageSizeOptions: ["8", "16", "32", "64", "72"],
                        showSizeChanger: true,
                        hideOnSinglePage: true,
                        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`
                    }} />}
                isEmpty={overtimes !== undefined && overtimes.length === 0}
                isEmptyText={intl.formatMessage({ defaultMessage: 'There are no events left to process' })}
                display={display}
                loading={loadingOvertime.current as any}
                dataByDate={overtimesByDate}
                changeDate={(startDate, endDate) => setSelectedDates({ startDate, endDate })}
                changeDisplay={setDisplay}
                changeSelectedDay={(selectedDay) => setSelectedDay(selectedDay)}
                selectedDay={selectedDay}
                icon={<Anticon icon={<TbClockCheck />} />}
                refresh={loadOvertimes}
            />
        </>
    );

};
export default OvertimesControl;
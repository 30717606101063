import moment from "moment";

const defaultFormats = {
    DATE_AND_ON_TIME: 'DD/MM/YYYY [at] HH:mm:ss',
    DATE_AND_ON_TIME_SHORT: 'DD/MM/YYYY [at] HH:mm',
    DATE_LONG_AND_ON_TIME_LONG: 'dddd, D MMMM YYYY [at] HH:mm:ss',
    DATE_LONG_AND_ON_TIME_SHORT: 'dddd, D MMMM YYYY [at] HH:mm',
    DATE_AND_TIME: 'DD/MM/YYYY HH:mm:ss',
    DATE_AND_TIME_SHORT: 'DD/MM/YYYY HH:mm',
    DATE_SHORT_AND_TIME: 'DD/MM/YY HH:mm:ss',
    DATE_SHORT_AND_TIME_SHORT: 'DD/MM/YY HH:mm',
    DATE_LONG_AND_TIME_LONG: 'dddd, D MMMM YYYY, HH:mm:ss',

    DATE: 'DD/MM/YYYY',
    DATE_LONG: 'dddd, D MMMM YYYY',
    DATE_MEDIUM: 'dddd D MMMM',
    DATE_SHORT: 'DD/MM/YY',
    DATE_DOW: 'DD/MM/YYYY (dd)',
    TIME: 'HH:mm:ss',
    TIME_SHORT: 'HH:mm',
    YEAR: 'YYYY',
    YEAR_SHORT: 'YY',
    MONTH: 'MMMM',
    MONTH_HALF: 'MMM',
    MONTH_SHORT: 'MM',
    DAY: 'dddd',
    DAY_MEDIUM: 'ddd',
    DAY_SHORT: 'DD',
    DAY_SINGLE_AND_MONTH_HALF: 'MMM D',
    DAY_SHORT_AND_MONTH_HALF: 'DD MMM',
    DAY_SHORT_AND_MONTH_HALF_AND_YEAR: 'DD MMM YYYY',
    DAY_SHORT_AND_MONTH_AND_YEAR: 'DD MMMM YYYY',
    DAY_SHORT_AND_MONTH_AND_YEAR_AND_TIME: 'DD MMMM YYYY HH:mm:ss',
};

type IFormats = typeof defaultFormats;

// Override default format
const overrides: { [key: string]: Partial<IFormats>; } = {
    fr: {
        DATE_AND_ON_TIME: 'DD.MM.YYYY [à] HH:mm:ss',
        DATE_AND_ON_TIME_SHORT: 'DD.MM.YYYY [à] HH:mm',
        DATE_LONG_AND_ON_TIME_LONG: 'dddd, D MMMM YYYY [à] HH:mm:ss',
        DATE_LONG_AND_ON_TIME_SHORT: 'dddd, D MMMM YYYY [à] HH:mm',
        DATE_AND_TIME: 'DD.MM.YYYY HH:mm:ss',
        DATE_AND_TIME_SHORT: 'DD.MM.YYYY HH:mm',
        DATE_SHORT_AND_TIME: 'DD.MM.YY HH:mm:ss',
        DATE_SHORT_AND_TIME_SHORT: 'DD.MM.YY HH:mm',
        DATE: 'DD.MM.YYYY',
        DAY_SINGLE_AND_MONTH_HALF: 'D MMM',
    },
    de: {
        DATE_AND_ON_TIME: 'DD.MM.YYYY [um] HH:mm:ss',
        DATE_AND_ON_TIME_SHORT: 'DD.MM.YYYY [um] HH:mm',
        DATE_LONG_AND_ON_TIME_LONG: 'dddd, D MMMM YYYY [um] HH:mm:ss',
        DATE_LONG_AND_ON_TIME_SHORT: 'dddd, D MMMM YYYY [um] HH:mm',
        DATE_AND_TIME: 'DD.MM.YYYY HH:mm:ss',
        DATE_AND_TIME_SHORT: 'DD.MM.YYYY HH:mm',
        DATE_SHORT_AND_TIME: 'DD.MM.YY HH:mm:ss',
        DATE_SHORT_AND_TIME_SHORT: 'DD.MM.YY HH:mm',
        DATE: 'DD.MM.YYYY',
    }
};

const getFormat = (f: keyof IFormats): string => {
    const locale = moment.locale();

    let o: IFormats;
    if (overrides[locale] !== undefined) {
        o = {
            ...defaultFormats,
            ...overrides[locale]
        };

        return o[f];
    }

    return defaultFormats[f];
};

export default getFormat;
import { Col, Row, Tooltip } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import floor from 'lodash/floor';
import toNumber from 'lodash/toNumber';
import moment, { Moment } from 'moment';
import React, { ReactNode } from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import '../../../styles/ccnt.css';
import { CcntTypeErrors, MOMENT_CCNT_DAY_FORMAT, MOMENT_CCNT_MONTH_FORMAT, MOMENT_MONTH_FORMAT } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { CctSecurity } from '../../../utils/objects/cct/cctSecurity';
import { NetworkAllHolidays, NetworkIncreasedHoursByDay } from '../../../utils/types/networkTypes';
import { AllHolidays } from '../../../utils/types/planningTypes';
import { CcntError, CcntEvent, CcntType, GroupByDayCcntType, IncreasedHoursByDay, SimpleEvent } from '../../../utils/types/reportTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { convertNetworkAllHolidaysToAllHolidays, convertNetworkAllIncreasedHoursByDayToAllIncreasedHoursByDay, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import Arrow from './FineLongArrow.png';

declare module "./FineLongArrow.png";

type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends ReduxProps, IntlProps {
    ccnt?: CcntType;
    toPrint?: boolean;
    year: Moment;
    ref?: React.LegacyRef<Ccnt> | undefined
}

interface State {
    starrDayDataCells: any[][][];

    starrWorkDone: number[];
    starrTheoreticalWork: number[];
    starrDeltaWork: number[];
    starrRestRecieved: number[];
    starrRestToGet: number[];
    starrDeltaRest: number[];
    starrPublicHolidayRecieved: number[];
    starrPublicHolidayToGet: number[];
    starrVacationRecieved: number[];
    starrVacationToGet: number[];
    starrMaternitySickness: number[];
    starrAccidents: number[];
    starrArmy: number[];
    starrOther: number[];

    stlastYearsWorkHours: number;
    stlastYearsRest: number;
    stlastYearsVacation: number;
    stlastYearsPublicHoliday: number;
    stpaidRest: number;
    stpaidVacation: number;
    stpaidPublicHolidays: number;

    totWorkDone: number;
    totTheoreticalWork: number;
    totDeltaWork: number;
    totRestRecieved: number;
    totRestToGet: number;
    totDeltaTest: number;
    totPublicHolidayRecieved: number;
    totPublicHolidayToGet: number;
    totVacationRecieved: number;
    totVacationToGet: number;
    totMaternitySickness: number;
    totAccidents: number;
    totArmy: number;
    totOther: number;
    totPaidDays: number;

    balanceWorkHours: number;
    balanceRest: number;
    balanceVacation: number;
    balancePublicHolidays: number;

    AllErrors: CcntError[];

    stCompany: string;
    stEmployee: string;
    stEntryDate: string;
    stExitDate: string;
    stWorkHoursPerWeek: number;
    sttargetYear: number;
    starrPaidHours: number[],

    stCcntWarningDefalutClass: string;

    allHolidays?: AllHolidays[];
    increasedhoursByDayLoading: boolean;
    increasedhoursByDay?: IncreasedHoursByDay[];
}

class Ccnt extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            starrDayDataCells: new Array(13).fill(0).map(() => new Array(32).fill(0).map(() => new Array(2).fill(""))),

            starrWorkDone: [],
            starrTheoreticalWork: [],
            starrDeltaWork: [],
            starrRestRecieved: [],
            starrRestToGet: [],
            starrDeltaRest: [],
            starrPublicHolidayRecieved: [],
            starrPublicHolidayToGet: [],
            starrVacationRecieved: [],
            starrVacationToGet: [],
            starrMaternitySickness: [],
            starrAccidents: [],
            starrArmy: [],
            starrOther: [],

            stlastYearsWorkHours: 0,
            stlastYearsRest: 0,
            stlastYearsVacation: 0,
            stlastYearsPublicHoliday: 0,
            stpaidRest: 0,
            stpaidVacation: 0,
            stpaidPublicHolidays: 0,

            totWorkDone: 0,
            totTheoreticalWork: 0,
            totDeltaWork: 0,
            totRestRecieved: 0,
            totRestToGet: 0,
            totDeltaTest: 0,
            totPublicHolidayRecieved: 0,
            totPublicHolidayToGet: 0,
            totVacationRecieved: 0,
            totVacationToGet: 0,
            totMaternitySickness: 0,
            totAccidents: 0,
            totArmy: 0,
            totOther: 0,
            totPaidDays: 0,

            balanceWorkHours: 0,
            balanceRest: 0,
            balanceVacation: 0,
            balancePublicHolidays: 0,

            AllErrors: [],

            stCompany: '',
            stEmployee: '',
            stEntryDate: '',
            stExitDate: '',
            stWorkHoursPerWeek: 0,
            sttargetYear: 0,
            starrPaidHours: [],

            stCcntWarningDefalutClass: 'ccntDefaultErrorMessageHidden',

            increasedhoursByDayLoading: false
        };

        this.getAllHolidays();
        this.getAllIncreasedhoursByDay();
    }

    getAllHolidays = () => {
        Network.getAllHolidays(this.props.year.startOf("year").format(MOMENT_MONTH_FORMAT), this.props.year.endOf("year").format(MOMENT_MONTH_FORMAT)).then(
            (response: NetworkAllHolidays[]) => {
                this.setState({ allHolidays: convertNetworkAllHolidaysToAllHolidays(response) });
            },
            () => {
                this.setState({ allHolidays: undefined });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the public holidays' }), "error");
            }
        );
    };
    getAllIncreasedhoursByDay = () => {
        this.setState({ increasedhoursByDayLoading: true });
        Network.getAllIncreasedhoursByDay(this.props.year.year()).then(
            (response: NetworkIncreasedHoursByDay[]) => {
                this.setState({ increasedhoursByDayLoading: false, increasedhoursByDay: convertNetworkAllIncreasedHoursByDayToAllIncreasedHoursByDay(response) });
            },
            () => {
                this.setState({ increasedhoursByDayLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the hours of the year {year}' }, { year: this.props.year.year().toString() }), "warning");
            }
        );
    };

    //function to groupe ccnt events by date / day
    groupBy = (x: CcntEvent[], f: any) => {
        return x.reduce((a: GroupByDayCcntType[], b: CcntEvent) => {
            const found = a.find(t => t.date === f(b));
            if (found) {
                found.events.push(b);
            } else {
                a.push({
                    date: f(b),
                    events: [b]
                });
            }
            return a;
        }, []);
    };

    //Force a number to have a minimal given number of decimals
    decimalHoursToHumanHours = (num: number) => {
        return ('0' + Math.floor(num) % 24).slice(-2) + ':' + ((num % 1) * 60 + '0').slice(0, 2);
    };

    // decimalHoursToHumanHours = (num: number) => {
    //     const hours = Math.floor(num);
    //     const minutes = Math.round((num - hours) * 60);
    //     return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    // };

    momentAtMidnight = (): Moment => {
        return moment().hour(0).minute(0).second(0).millisecond(0);
    };

    //Method that calculat all the fields and elements of the Ccnt chart
    //extracts data from an CcntType object  
    calculateData = () => {
        const allDaysJSON = this.props.ccnt;
        const { intl } = this.props;
        if (!allDaysJSON) return;

        //get general data
        const company: string = allDaysJSON.company;
        const employee: string = allDaysJSON.employee;
        const entryDate: string = moment(allDaysJSON.entryDate).format("DD/MM/YYYY");
        const exitDate: string = moment(allDaysJSON.exitDate).format("DD/MM/YYYY");
        const workHoursPerWeek: number = allDaysJSON.weeklyPlannedHours;

        //test data table for a month
        const lastYearsWorkHours: number = allDaysJSON.lastYearsWorkHours;
        const lastYearsRest: number = allDaysJSON.lastYearsRest;
        const lastYearsVacation: number = allDaysJSON.lastYearsVacation;
        const lastYearsPublicHoliday: number = allDaysJSON.lastYearsPublicVacation;
        const paidRest: number = allDaysJSON.paidRestDays;
        const paidVacation: number = allDaysJSON.paidVacationDays;
        const paidPublicHolidays: number = allDaysJSON.paidPublicHolidayDays;
        const targetYear: number = allDaysJSON.workYear;
        const arrPaidHours: number[] = allDaysJSON.paidHours;

        //updates current state
        this.setState({
            stlastYearsWorkHours: lastYearsWorkHours,
            stlastYearsRest: lastYearsRest,
            stlastYearsVacation: lastYearsVacation,
            stlastYearsPublicHoliday: lastYearsPublicHoliday,
            stpaidRest: paidRest,
            stpaidVacation: paidVacation,
            stpaidPublicHolidays: paidPublicHolidays,
            sttargetYear: targetYear,
            stCompany: company,
            stEmployee: employee,
            stEntryDate: entryDate,
            stExitDate: exitDate,
            stWorkHoursPerWeek: workHoursPerWeek,
            starrPaidHours: arrPaidHours,
        });

        const errors: CcntError[] = [];

        //extract all JSON
        const objectMonths: GroupByDayCcntType[] = this.groupBy(allDaysJSON.events, (event: CcntEvent) => event.dateFrom.format(MOMENT_CCNT_MONTH_FORMAT));

        //monthly paid hours
        const monthPaidHours: number[] = allDaysJSON.paidHours;

        //Arrays for every information needed
        const arrWorkDone = new Array(12).fill((Math.round(0 * 100) / 100).toFixed(2));
        const arrTheoreticalWork = new Array(12).fill((Math.round(0 * 100) / 100).toFixed(2));
        const arrDeltaWork = new Array(12).fill((Math.round(0 * 100) / 100).toFixed(2));
        const arrRestRecieved = new Array(12).fill((Math.round(0 * 100) / 100).toFixed(2));
        const arrRestToGet = new Array(12).fill((Math.round(0 * 100) / 100).toFixed(2));
        const arrDeltaRest = new Array(12).fill((Math.round(0 * 100) / 100).toFixed(2));
        const arrPublicHolidayRecieved = new Array(12).fill((Math.round(0 * 100) / 100).toFixed(2));
        const arrPublicHolidayToGet = new Array(12).fill((Math.round(0 * 100) / 100).toFixed(2));
        const arrVacationRecieved = new Array(12).fill((Math.round(0 * 100) / 100).toFixed(2));
        const arrVacationToGet = new Array(12).fill((Math.round(0 * 100) / 100).toFixed(2));
        const arrMaternitySickness = new Array(12).fill((Math.round(0 * 100) / 100).toFixed(2));
        const arrAccidents = new Array(12).fill((Math.round(0 * 100) / 100).toFixed(2));
        const arrArmy = new Array(12).fill((Math.round(0 * 100) / 100).toFixed(2));
        const arrOther = new Array(12).fill((Math.round(0 * 100) / 100).toFixed(2));

        //Arrays for left table display
        const arrDayDataCells = new Array(13).fill(0).map(() => new Array(32).fill(0).map(() => new Array(2).fill("")));
        let nbWorkDaysInARow = 0;
        let nbRestDaysPerWeek = 0;
        let nbWorkHoursPerWeek = 0;
        let nbDaysWithEvents = 0;

        //calcul for each month of the year- - - - - - - - - - - - - - - - - - - - - - - - - - -
        objectMonths.forEach(month => {

            //group events by days[day]
            const days = this.groupBy(month.events, (event: CcntEvent) => event.dateFrom.format(MOMENT_CCNT_DAY_FORMAT));
            const idxMonth = toNumber(month.date.substring(0, 2)) - 1;

            //variables per month
            let monthTTime = 0;//time is always calculated in hours
            let monthV = 0;
            let monthX = 0;
            let monthF = 0;
            let monthMi = 0;
            let monthM = 0;
            let monthA = 0;
            let monthMa = 0;
            let monthD = 0;
            let usedDays = 0;


            // calcul for each day - - - - - - - - - - - - - - - - - - - - - - - - - - -
            days.forEach((dayIni) => {
                const day = cloneDeep(dayIni);
                // Temporary correction for badged event (not have type of day)
                if (this.props.company?.isTimeClockEnabled) {
                    day.events = dayIni.events.map((e: CcntEvent) => {
                        return {
                            ...e,
                            type: e.type === "Unkown" ? "T" : e.type
                        };
                    });
                }
                nbDaysWithEvents++;
                const idxDay = toNumber(day.date.substring(0, 2));
                const dailyDate = day.date;
                let hasOvertimeError = false;
                let hasOverXError = false;
                let hasInputMixError = false;
                let dailyWorkTime = 0;
                usedDays++;

                //if there is more than 1 event per day, it separates the events in on table with the work events, and one with the other 
                if (day.events.length > 1) {
                    let dailyX = 0;
                    let dailyF = 0;
                    let dailyM = 0;


                    const workEvents = day.events.filter((day: CcntEvent) => day.type === "T");

                    const otherEvents = day.events.filter((day: CcntEvent) => day.type !== "T");

                    // calculate time for each work event

                    workEvents.forEach((workEvent: CcntEvent) => {
                        let workOvertime = 0;
                        let workBreaktime = 0;

                        //adding overtimes and breaktimes
                        if (workEvent.overtime.length >= 1) {
                            for (let i = 0; i < workEvent.overtime.length; i++) {
                                const evtdateFrom = workEvent.overtime[i].dateFrom;
                                const evtdateTo = workEvent.overtime[i].dateTo;
                                let time = moment.duration(evtdateTo.diff(evtdateFrom)).asHours();
                                if (workEvent.overtime[i].isNegative === true) {
                                    time = time * -1;
                                }

                                workOvertime += time;
                            }
                        }
                        if (workEvent.breaktime.length >= 1) {
                            for (let i = 0; i < workEvent.breaktime.length; i++) {
                                const evtdateFrom = workEvent.breaktime[i].dateFrom;
                                const evtdateTo = workEvent.breaktime[i].dateTo;
                                const time = moment.duration(evtdateTo.diff(evtdateFrom)).asHours();

                                workBreaktime += time;
                            }
                        }

                        //calculat the final worktime of the current day
                        const workDayDateFrom = workEvent.dateFrom;
                        const workDayDateTo = workEvent.dateTo;
                        const workDayMoment = moment.duration(workDayDateTo.diff(workDayDateFrom)).asHours();

                        const event: SimpleEvent = {
                            id: 1,
                            title: "Event",
                            dateFrom: workEvent.dateFrom,
                            dateTo: workEvent.dateTo,
                            overtimes: [],
                            breakTimes: workEvent.breaktime
                        };

                        const increasedHours = CctSecurity.calculateIncreaseHours(cloneDeep(event), workOvertime, this.state.increasedhoursByDay, this.state.allHolidays);

                        const workDayWorkDuration = workDayMoment + workOvertime - workBreaktime + increasedHours;

                        dailyWorkTime += workDayWorkDuration;

                        arrDayDataCells[idxMonth][idxDay][0] = "T";
                        const integerDayWorkDuration = floor(dailyWorkTime);

                        const workTimeInHoursMinFormat = `${integerDayWorkDuration < 10 ? "0" : ""}${integerDayWorkDuration}:${this.momentAtMidnight().add(dailyWorkTime, 'hours').format("mm")}`;
                        arrDayDataCells[idxMonth][idxDay][1] = workTimeInHoursMinFormat;

                        //if worktime on the given day is over 14h or 24h it throws an warning to the user
                        if (dailyWorkTime > 14 && workEvents[workEvents.length - 1] === workEvent) {
                            if (dailyWorkTime > 24) {
                                const newError: CcntError = {
                                    date: dailyDate,
                                    code: "WarningOver24",
                                    type: CcntTypeErrors.Warning,
                                    message: `<strong>${intl.formatMessage({ defaultMessage: 'Warning' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'More than 24 hours of work in a single day' })}<br/>${intl.formatMessage({ defaultMessage: 'Check plausibility' })}`
                                };
                                if (errors.find(e => e.date === newError.date) === undefined ||
                                    errors.find(e => e.date === newError.date && e.code === newError.code) === undefined) {
                                    errors.push(newError);
                                }
                            } else {
                                const newError: CcntError = {
                                    date: dailyDate,
                                    code: "InfoLongDay",
                                    type: CcntTypeErrors.Info,
                                    message: `<strong>${intl.formatMessage({ defaultMessage: 'Information' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'More than 14 hours of work in a single day' })}`
                                };
                                if (errors.find(e => e.date === newError.date) === undefined ||
                                    errors.find(e => e.date === newError.date && e.code === newError.code) === undefined) {
                                    errors.push(newError);
                                }
                            }
                        }

                        //if workhours are negativ it throws a warning
                        if (dailyWorkTime < 0) {
                            const newError: CcntError = {
                                date: dailyDate,
                                code: "WarningNegativHours",
                                type: CcntTypeErrors.Warning,
                                message: `<strong>${intl.formatMessage({ defaultMessage: 'Warning' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'Negative hours' })}`
                            };
                            if (errors.find(e => e.date === newError.date) === undefined ||
                                errors.find(e => e.date === newError.date && e.code === newError.code) === undefined) {
                                errors.push(newError);
                            }
                        }

                    });

                    if (dailyWorkTime > 0) {
                        nbWorkDaysInARow++;
                        if (nbWorkDaysInARow > 6) {
                            const newError: CcntError = {
                                date: dailyDate,
                                code: "WarningNegativHours", // TODO : Mauvais code d'erreur, à corriger
                                type: CcntTypeErrors.Warning,
                                message: `<strong>${intl.formatMessage({ defaultMessage: 'Warning' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'More than 6 working days without rest days' })}`
                            };
                            if (errors.find(e => e.date === newError.date) === undefined ||
                                errors.find(e => e.date === newError.date && e.code === newError.code) === undefined) {
                                errors.push(newError);
                            }
                        }
                    } else {
                        nbWorkDaysInARow = 0;
                    }

                    nbWorkHoursPerWeek += dailyWorkTime;

                    //adding the daily worktime to the monthly worktime
                    monthTTime += dailyWorkTime;

                    //calculat the data for every other type of event
                    otherEvents.forEach((otherEvent: CcntEvent) => {
                        //if an event of type X or F is on a work day, it adds the worktime and half-rest normaly
                        //if worktime goes over 5h, the half rest or public vacation day is not added and it throws an error to the user 
                        if (otherEvent.type === "X" && dailyWorkTime <= 5) {
                            nbRestDaysPerWeek += 0.5;
                            nbWorkDaysInARow = 0;
                            monthX = monthX + 0.5;
                            dailyX++;
                            const integerDayWorkDuration = floor(dailyWorkTime);
                            const workTimeInHoursMinFormat = `${integerDayWorkDuration < 10 ? "0" : ""}${integerDayWorkDuration}:${this.momentAtMidnight().add(dailyWorkTime, 'hours').format("mm")}`;

                            //if there is an workevent, X/F and worktime are shown normal(X|8:24)
                            //if there is no workevent X/F will show in the order of the object ex:(D|X)
                            if (workEvents.length > 0) {
                                arrDayDataCells[idxMonth][idxDay][0] = "X";
                                arrDayDataCells[idxMonth][idxDay][1] = workTimeInHoursMinFormat;
                            } else {
                                if (otherEvent === otherEvents[0]) {
                                    arrDayDataCells[idxMonth][idxDay][0] = "X";
                                } else {
                                    arrDayDataCells[idxMonth][idxDay][1] = "X";
                                }
                            }
                        }

                        if (otherEvent.type === "F" && dailyWorkTime <= 5) {
                            nbWorkDaysInARow = 0;
                            monthF = monthF + 0.5;
                            dailyF++;
                            const integerDayWorkDuration = floor(dailyWorkTime);
                            const workTimeInHoursMinFormat = `${integerDayWorkDuration < 10 ? "0" : ""}${integerDayWorkDuration}:${this.momentAtMidnight().add(dailyWorkTime, 'hours').format("mm")}`;

                            //if there is an workevent, X/F and worktime are shown normal(X|8:24)
                            //if there is no workevent X/F will show in the order of the object ex:(D|X)
                            if (workEvents.length > 0) {
                                arrDayDataCells[idxMonth][idxDay][0] = "F";
                                arrDayDataCells[idxMonth][idxDay][1] = workTimeInHoursMinFormat;
                            } else {
                                if (otherEvent === otherEvents[0]) {
                                    arrDayDataCells[idxMonth][idxDay][0] = "F";
                                } else {
                                    arrDayDataCells[idxMonth][idxDay][1] = "F";
                                }
                            }
                        }

                        if (otherEvent.type === "M" && dailyWorkTime <= 5) {
                            nbWorkDaysInARow = 0;
                            monthM = monthM + 0.5;
                            dailyM++;
                            const integerDayWorkDuration = floor(dailyWorkTime);
                            const workTimeInHoursMinFormat = `${integerDayWorkDuration < 10 ? "0" : ""}${integerDayWorkDuration}:${this.momentAtMidnight().add(dailyWorkTime, 'hours').format("mm")}`;

                            //if there is an workevent, X/F and worktime are shown normal(X|8:24)
                            //if there is no workevent X/F will show in the order of the object ex:(D|X)
                            if (workEvents.length > 0) {
                                arrDayDataCells[idxMonth][idxDay][0] = "M";
                                arrDayDataCells[idxMonth][idxDay][1] = workTimeInHoursMinFormat;
                            } else {
                                if (otherEvent === otherEvents[0]) {
                                    arrDayDataCells[idxMonth][idxDay][0] = "M";
                                } else {
                                    arrDayDataCells[idxMonth][idxDay][1] = "M";
                                }
                            }
                        }
                        if (otherEvent.type === "X" && dailyWorkTime > 5 && !hasOvertimeError) {
                            dailyX++;

                            const newError: CcntError = {
                                date: dailyDate,
                                code: "OvertimeOnHalfRestDay",
                                type: CcntTypeErrors.CcntWarning,
                                message: `<strong>${intl.formatMessage({ defaultMessage: 'Warning' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'More than 5 hours work on a half-day off.' })}<br/>${intl.formatMessage({ defaultMessage: 'The half-day of leave/separation/sickness will not be counted.' })}<br/>${intl.formatMessage({ defaultMessage: 'This warning will be displayed on the printed version of the report.' })}`
                            };

                            //switching the printable default ccnt warning to visible
                            this.setState({
                                stCcntWarningDefalutClass: 'ccntDefaultErrorMessage'
                            });

                            errors.push(newError);
                            hasOvertimeError = true;
                            const integerDayWorkDuration = floor(dailyWorkTime);
                            const workTimeInHoursMinFormat = `${integerDayWorkDuration < 10 ? "0" : ""}${integerDayWorkDuration}:${this.momentAtMidnight().add(dailyWorkTime, 'hours').format("mm")}`;

                            //if there is an workevent, X/F and worktime are shown normal(X|8:24)
                            //if there is no workevent X/F will show in the order of the object ex:(D|X)
                            if (workEvents.length > 0) {
                                arrDayDataCells[idxMonth][idxDay][0] = "X";
                                arrDayDataCells[idxMonth][idxDay][1] = workTimeInHoursMinFormat;
                            } else {
                                if (otherEvent === otherEvents[0]) {
                                    arrDayDataCells[idxMonth][idxDay][0] = "X";
                                } else {
                                    arrDayDataCells[idxMonth][idxDay][1] = "X";
                                }
                            }
                        }

                        if (otherEvent.type === "F" && dailyWorkTime > 5 && !hasOvertimeError) {
                            dailyF++;

                            const newError: CcntError = {
                                date: dailyDate,
                                code: "OvertimeOnHalfRestDay",
                                type: CcntTypeErrors.CcntWarning,
                                message: `<strong>${intl.formatMessage({ defaultMessage: 'Warning' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'More than 5 hours work on a half-day off.' })}<br/>${intl.formatMessage({ defaultMessage: 'The half-day of leave/separation/sickness will not be counted.' })}<br/>${intl.formatMessage({ defaultMessage: 'This warning will be displayed on the printed version of the report.' })}`
                            };

                            //switching the printable default ccnt warning to visible
                            this.setState({
                                stCcntWarningDefalutClass: 'ccntDefaultErrorMessage'
                            });

                            errors.push(newError);
                            hasOvertimeError = true;
                            const integerDayWorkDuration = floor(dailyWorkTime);
                            const workTimeInHoursMinFormat = `${integerDayWorkDuration < 10 ? "0" : ""}${integerDayWorkDuration}:${this.momentAtMidnight().add(dailyWorkTime, 'hours').format("mm")}`;

                            //if there is an workevent, X/F and worktime are shown normal(X|8:24)
                            //if there is no workevent X/F will show in the order of the object ex:(D|X)
                            if (workEvents.length > 0) {
                                arrDayDataCells[idxMonth][idxDay][0] = "F";
                                arrDayDataCells[idxMonth][idxDay][1] = workTimeInHoursMinFormat;
                            } else {
                                if (otherEvent === otherEvents[0]) {
                                    arrDayDataCells[idxMonth][idxDay][0] = "F";
                                } else {
                                    arrDayDataCells[idxMonth][idxDay][1] = "F";
                                }
                            }
                        }

                        if (otherEvent.type === "M" && dailyWorkTime > 5 && !hasOvertimeError) {
                            dailyM++;

                            const newError: CcntError = {
                                date: dailyDate,
                                code: "OvertimeOnHalfRestDay",
                                type: CcntTypeErrors.CcntWarning,
                                message: `<strong>${intl.formatMessage({ defaultMessage: 'Warning' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'More than 5 hours work on a half-day off.' })}<br/>${intl.formatMessage({ defaultMessage: 'The half-day of leave/separation/sickness will not be counted.' })}<br/>${intl.formatMessage({ defaultMessage: 'This warning will be displayed on the printed version of the report.' })}`
                            };

                            //switching the printable default ccnt warning to visible
                            this.setState({
                                stCcntWarningDefalutClass: 'ccntDefaultErrorMessage'
                            });

                            errors.push(newError);
                            hasOvertimeError = true;
                            const integerDayWorkDuration = floor(dailyWorkTime);
                            const workTimeInHoursMinFormat = `${integerDayWorkDuration < 10 ? "0" : ""}${integerDayWorkDuration}:${this.momentAtMidnight().add(dailyWorkTime, 'hours').format("mm")}`;

                            //if there is an workevent, X/F and worktime are shown normal(X|8:24)
                            //if there is no workevent X/F will show in the order of the object ex:(D|X)
                            if (workEvents.length > 0) {
                                arrDayDataCells[idxMonth][idxDay][0] = "M";
                                arrDayDataCells[idxMonth][idxDay][1] = workTimeInHoursMinFormat;
                            } else {
                                if (otherEvent === otherEvents[0]) {
                                    arrDayDataCells[idxMonth][idxDay][0] = "M";
                                } else {
                                    arrDayDataCells[idxMonth][idxDay][1] = "M";
                                }
                            }
                        }

                        //if the other event are not of type X, T or F it throws a warning, but calculates the data normally
                        //if there is more than 2 different types of event on a single day it shows an error to the user, the data should still be calculated correctly, but there is an high risk of 
                        //error and it will show false informations on the Ccnt table
                        let dayWorkDuration: number;
                        if (otherEvent.type !== "X" && otherEvent.type !== "T" && otherEvent.type !== "F" && otherEvent.type !== "M") {
                            if (!hasInputMixError) {
                                const newError: CcntError = {
                                    date: dailyDate,
                                    code: "ErrorInputMix",
                                    type: CcntTypeErrors.Warning,
                                    message: `<strong>${intl.formatMessage({ defaultMessage: 'Warning' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'Incompatible types of day according to N-CLA standards.' })}<br/>${intl.formatMessage({ defaultMessage: 'If several event types are present on the same day, check that they are of type X, F, M or T.' })}`
                                };
                                errors.push(newError);
                                hasInputMixError = true;
                            }

                            if (otherEvents.length < 3) {
                                let rawIdx;
                                if (otherEvent === otherEvents[0]) {
                                    rawIdx = 0;
                                } else {
                                    rawIdx = 1;
                                }

                                switch (otherEvent.type) {
                                    case "V":
                                        nbRestDaysPerWeek += 1;
                                        monthV += (1 / day.events.length);
                                        arrDayDataCells[idxMonth][idxDay][rawIdx] = "V";
                                        break;

                                    case "Mi":
                                        monthMi += (1 / day.events.length);
                                        arrDayDataCells[idxMonth][idxDay][rawIdx] = "Mi";
                                        break;

                                    case "M":
                                        monthM += (1 / day.events.length);
                                        arrDayDataCells[idxMonth][idxDay][rawIdx] = "M";
                                        break;

                                    case "A":
                                        monthA += (1 / day.events.length);
                                        arrDayDataCells[idxMonth][idxDay][rawIdx] = "A";
                                        break;

                                    case "Ma":
                                        monthMa += (1 / day.events.length);
                                        arrDayDataCells[idxMonth][idxDay][rawIdx] = "Ma";
                                        break;

                                    case "D":
                                        monthD += (1 / day.events.length);
                                        arrDayDataCells[idxMonth][idxDay][rawIdx] = "D";
                                        break;
                                    default: {
                                        dayWorkDuration = 0;
                                        nbWorkHoursPerWeek += dayWorkDuration;
                                        monthTTime = monthTTime + dayWorkDuration;
                                        const newError: CcntError = {
                                            date: dailyDate,
                                            code: "ErrorWrongInput",
                                            type: CcntTypeErrors.Error,
                                            message: `<strong>${intl.formatMessage({ defaultMessage: 'Error' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'Invalid type of day. Correct type of day.' })}<br/>${intl.formatMessage({ defaultMessage: 'Valid types: T | X | F | V | Mi | M | A | Ma | D' })}`
                                        };
                                        errors.push(newError);
                                        arrDayDataCells[idxMonth][idxDay][1] = otherEvent.type;
                                    }
                                }
                            } else {
                                const newError: CcntError = {
                                    date: dailyDate,
                                    code: "ErrorTooManyDifferentEvents",
                                    type: CcntTypeErrors.Error,
                                    message: `<strong>${intl.formatMessage({ defaultMessage: 'Error' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'Too many different events on the same day.' })}<br/>${intl.formatMessage({ defaultMessage: ' - Check that there are no more than two types of day.' })}<br/>${intl.formatMessage({ defaultMessage: ' - Check that there are no more than 2 events if they are not of type X, F or T.' })}`
                                };
                                if (errors.find(e => e.date === newError.date) === undefined ||
                                    errors.find(e => e.date === newError.date && e.code === newError.code) === undefined) {
                                    errors.push(newError);
                                }
                            }
                        }


                        //if there is more than one X or F event on a same day it shows a warning, only 1 should be put in by the user
                        //the data should still be correct with 2 or more X or F events, but it should be avoided
                        if (dailyX > 1 && !hasOverXError) {
                            const newError: CcntError = {
                                date: dailyDate,
                                code: "WarningOverX",
                                type: CcntTypeErrors.Warning,
                                message: `<strong>${intl.formatMessage({ defaultMessage: 'Warning' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'Several rest events (X) on the same day.' })}`
                            };
                            errors.push(newError);
                            hasOverXError = true;
                        }
                        if (dailyF > 1 && !hasOverXError) {
                            const newError: CcntError = {
                                date: dailyDate,
                                code: "WarningOverM",
                                type: CcntTypeErrors.Warning,
                                message: `<strong>${intl.formatMessage({ defaultMessage: 'Warning' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'Several illness (M) events on the same day.' })}`
                            };
                            errors.push(newError);
                            hasOverXError = true;
                        }
                        if (dailyM > 1 && !hasOverXError) {
                            const newError: CcntError = {
                                date: dailyDate,
                                code: "WarningOverF",
                                type: CcntTypeErrors.Warning,
                                message: `<strong>${intl.formatMessage({ defaultMessage: 'Warning' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'Several illness (M) events on the same day.' })}` //TODO @Mario @Mathias @Axel vérifier le text ici, identique à celui d'en haut
                            };
                            errors.push(newError);
                            hasOverXError = true;
                        }
                    });

                    //algoritme to verify if a day has not more than 2 diffrent event types per day
                    const countOccurrences = (arr: CcntEvent[], val: string) => arr.reduce((a, v) => (v.type === val ? a + 1 : a), 0);
                    let amountT = 0;
                    let amountX = 0;
                    let amountF = 0;
                    let amountV = 0;
                    let amountMi = 0;
                    let amountM = 0;
                    let amountA = 0;
                    let amountMa = 0;
                    let amountD = 0;

                    amountT = countOccurrences(day.events, "T");
                    amountX = countOccurrences(day.events, "X");
                    amountF = countOccurrences(day.events, "F");
                    amountV = countOccurrences(day.events, "V");
                    amountMi = countOccurrences(day.events, "Mi");
                    amountM = countOccurrences(day.events, "M");
                    amountA = countOccurrences(day.events, "A");
                    amountMa = countOccurrences(day.events, "Ma");
                    amountD = countOccurrences(day.events, "D");

                    // T, X, and F can be present multiple times without posing an issue, so even if they are multiple events of one of this types it counts as 1
                    amountT = ((amountT > 1) ? 1 : amountT);
                    amountX = ((amountX > 1) ? 1 : amountX);
                    amountF = ((amountF > 1) ? 1 : amountF);

                    const amountOfDifferentEvents = amountT + amountX + amountF + amountV + amountMi + amountM + amountA + amountMa + amountD;

                    //if the amount of different event types exceeds 2 it shows an error to the user
                    //there is a very high risk of error and the result shown on the Ccnt table will not show all events and be wrong
                    if (amountOfDifferentEvents > 2) {
                        const newError: CcntError = {
                            date: dailyDate,
                            code: "ErrorTooManyDifferentEvents",
                            type: CcntTypeErrors.Error,
                            message: `<strong>${intl.formatMessage({ defaultMessage: 'Error' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'Too many different events on the same day.' })}<br/>${intl.formatMessage({ defaultMessage: ' - Check that there are no more than two types of day.' })}<br/>${intl.formatMessage({ defaultMessage: ' - Check that there are no more than 2 events if they are not of type X, F or T.' })}`
                        };

                        if (errors.find(e => e.date === newError.date) === undefined ||
                            errors.find(e => e.date === newError.date && e.code === newError.code) === undefined) {
                            errors.push(newError);
                        }
                    }
                }
                else // calcul for normal days, where there is only 1 event
                {
                    let workOvertime = 0;
                    let workBreaktime = 0;

                    //calcul of the overtimes and breaktimes
                    if (day.events[0].overtime.length >= 1) {
                        // This is because Patrice took the length of events instead of the length of overtimes. Meaning only 1 overtime was taken into account.
                        // We want to fix this but only from the 1st april 2024 so we don't change the already signed reports.
                        const day_date = moment.utc(day.date, "DD/MM/YYYY");
                        if (day_date.isSameOrBefore(moment("30/03/2024", "DD/MM/YYYY"))) {
                            for (let i = 0; i < day.events.length; i++) {
                                const daydateFrom = day.events[0].overtime[i].dateFrom;
                                const daydateTo = day.events[0].overtime[i].dateTo;
                                let time = moment.duration(daydateTo.diff(daydateFrom)).asHours();

                                if (day.events[0].overtime[i].isNegative === true) {
                                    time = time * -1;
                                }

                                workOvertime += time;
                            }
                        }
                        else {
                            for (let i = 0; i < day.events[0].overtime.length; i++) {
                                const daydateFrom = day.events[0].overtime[i].dateFrom;
                                const daydateTo = day.events[0].overtime[i].dateTo;
                                let time = moment.duration(daydateTo.diff(daydateFrom)).asHours();

                                if (day.events[0].overtime[i].isNegative === true) {
                                    time = time * -1;
                                }

                                workOvertime += time;
                            }
                        }
                    }

                    if (day.events[0].breaktime.length >= 1) {
                        for (let i = 0; i < day.events[0].breaktime.length; i++) {
                            const daydateFrom = day.events[0].breaktime[i].dateFrom;
                            const daydateTo = day.events[0].breaktime[i].dateTo;
                            const time = moment.duration(daydateTo.diff(daydateFrom)).asHours();
                            workBreaktime += time;
                        }
                    }

                    //final calcul of the daily worktime
                    const dayType = day.events[0].type;
                    const dateFrom = day.events[0].dateFrom;
                    const dateTo = day.events[0].dateTo;
                    const dayMoment = moment.duration(dateTo.diff(dateFrom)).asHours();
                    let increasedHours = 0;
                    if (dayType === "T") {
                        const event: SimpleEvent = {
                            id: 1,
                            title: "Event",
                            dateFrom: dateFrom,
                            dateTo: dateTo,
                            overtimes: [],
                            breakTimes: day.events[0].breaktime
                        };

                        increasedHours = CctSecurity.calculateIncreaseHours(event, workOvertime, this.state.increasedhoursByDay, this.state.allHolidays);
                    }
                    const dayWorkDuration = dayMoment + workOvertime - workBreaktime + increasedHours;
                    dailyWorkTime = dayWorkDuration;

                    if (day.events[0].dateFrom.format("YYYY-MM-DD") === '2024-01-02')
                        console.log("Daily work time", {
                            work: dayMoment,
                            overtime: workOvertime,
                            breaktime: workBreaktime,
                            increasedHours,
                            total: dayWorkDuration,
                            dailyWorkTime
                        });
                    // calculat total amount of days[day] type per month
                    switch (dayType) {
                        case "T": {
                            if (dayWorkDuration > 0) {
                                nbWorkDaysInARow++;
                            } else {
                                nbWorkDaysInARow = 0;
                            }
                            nbWorkHoursPerWeek += dayWorkDuration;
                            monthTTime = monthTTime + dayWorkDuration;
                            if (dayWorkDuration > 14) {
                                if (dayWorkDuration > 24) {
                                    const newError: CcntError = {
                                        date: dailyDate,
                                        code: "WarningOver24",
                                        type: CcntTypeErrors.Warning,
                                        message: `<strong>${intl.formatMessage({ defaultMessage: 'Warning' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'More than 24 hours of work in a single day' })}<br/>${intl.formatMessage({ defaultMessage: 'Check plausibility' })}`
                                    };
                                    errors.push(newError);
                                } else {
                                    const newError: CcntError = {
                                        date: dailyDate,
                                        code: "InfoLongDay",
                                        type: CcntTypeErrors.Info,
                                        message: `<strong>${intl.formatMessage({ defaultMessage: 'Information' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'More than 14 hours of work in a single day' })}`
                                    };
                                    errors.push(newError);
                                }
                            }
                            //if work hours are negativ a warning is shown
                            if (dayWorkDuration < 0) {
                                const newError: CcntError = {
                                    date: dailyDate,
                                    code: "WarningNegativHours",
                                    type: CcntTypeErrors.Warning,
                                    message: `<strong>${intl.formatMessage({ defaultMessage: 'Warning' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'Negative hours' })}`
                                };
                                if (errors.find(e => e.date === newError.date) === undefined ||
                                    errors.find(e => e.date === newError.date && e.code === newError.code) === undefined) {
                                    errors.push(newError);
                                }
                            }

                            if (nbWorkDaysInARow > 6) {
                                const newError: CcntError = {
                                    date: dailyDate,
                                    code: "WarningNegativHours",
                                    type: CcntTypeErrors.Warning,
                                    message: `<strong>${intl.formatMessage({ defaultMessage: 'Warning' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'More than 6 working days without rest days' })}`
                                };
                                if (errors.find(e => e.date === newError.date) === undefined ||
                                    errors.find(e => e.date === newError.date && e.code === newError.code) === undefined) {
                                    errors.push(newError);
                                }
                            }

                            arrDayDataCells[idxMonth][idxDay][0] = "T";
                            const integerDayWorkDuration = floor(dayWorkDuration);
                            const workTimeInHoursMinFormat = `${integerDayWorkDuration < 10 ? "0" : ""}${integerDayWorkDuration}:${this.momentAtMidnight().add(dayWorkDuration, 'hours').format("mm")}`;
                            arrDayDataCells[idxMonth][idxDay][1] = workTimeInHoursMinFormat;
                            break;
                        }
                        case "V":
                            nbRestDaysPerWeek += 1;
                            nbWorkDaysInARow = 0;
                            monthV++;
                            arrDayDataCells[idxMonth][idxDay][0] = "V";
                            arrDayDataCells[idxMonth][idxDay][1] = "V";
                            break;

                        case "X":
                            nbRestDaysPerWeek += 1;
                            nbWorkDaysInARow = 0;
                            monthX++;
                            arrDayDataCells[idxMonth][idxDay][0] = "X";
                            arrDayDataCells[idxMonth][idxDay][1] = "X";
                            break;

                        case "F":
                            nbRestDaysPerWeek += 1;
                            nbWorkDaysInARow = 0;
                            monthF++;
                            arrDayDataCells[idxMonth][idxDay][0] = "F";
                            arrDayDataCells[idxMonth][idxDay][1] = "F";
                            break;

                        case "Mi":
                            nbRestDaysPerWeek += 1;
                            monthMi++;
                            arrDayDataCells[idxMonth][idxDay][0] = "Mi";
                            arrDayDataCells[idxMonth][idxDay][1] = "Mi";
                            break;

                        case "M":
                            nbRestDaysPerWeek += 1;
                            monthM++;
                            arrDayDataCells[idxMonth][idxDay][0] = "M";
                            arrDayDataCells[idxMonth][idxDay][1] = "M";
                            break;

                        case "A":
                            nbRestDaysPerWeek += 1;
                            monthA++;
                            arrDayDataCells[idxMonth][idxDay][0] = "A";
                            arrDayDataCells[idxMonth][idxDay][1] = "A";
                            break;

                        case "Ma":
                            nbRestDaysPerWeek += 1;
                            monthMa++;
                            arrDayDataCells[idxMonth][idxDay][0] = "Ma";
                            arrDayDataCells[idxMonth][idxDay][1] = "Ma";
                            break;

                        case "D":
                            monthD++;
                            arrDayDataCells[idxMonth][idxDay][0] = "D";
                            arrDayDataCells[idxMonth][idxDay][1] = "D";
                            break;

                        default: {
                            nbWorkHoursPerWeek += dayWorkDuration;
                            monthTTime = monthTTime + dayWorkDuration;
                            const newError: CcntError = {
                                date: dailyDate,
                                code: "ErrorWrongInput",
                                type: CcntTypeErrors.Error,
                                message: `<strong>${intl.formatMessage({ defaultMessage: 'Error' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'Invalid type of day. Correct type of day.' })}<br/>${intl.formatMessage({ defaultMessage: 'Valid types: T | X | F | V | Mi | M | A | Ma | D' })}`
                            };
                            errors.push(newError);
                        }
                    }
                }

                const dateFrom = day.events[0].dateFrom;
                if (dateFrom.isoWeekday() === 7) {
                    if (nbDaysWithEvents >= 7) {
                        if (nbRestDaysPerWeek < 2) {
                            const newError: CcntError = {
                                date: dailyDate,
                                code: "WeekWithout2RestDays",
                                type: CcntTypeErrors.Warning,
                                message: `<strong>${intl.formatMessage({ defaultMessage: 'Warning' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'Week without 2 days off ({start} - {end})' }, { start: dateFrom.clone().subtract(6, "days").format(getFormat('DATE')), end: dateFrom.format(getFormat('DATE')) })}`
                            };

                            if (errors.find(e => e.date === newError.date) === undefined ||
                                errors.find(e => e.date === newError.date && e.code === newError.code) === undefined) {
                                errors.push(newError);
                            }
                        }
                    }

                    if (nbWorkHoursPerWeek > 50) {
                        const newError: CcntError = {
                            date: dailyDate,
                            code: "WeekWithOver50Hours",
                            type: CcntTypeErrors.Warning,
                            message: `<strong>${intl.formatMessage({ defaultMessage: 'Warning' })}:</strong><br/>${intl.formatMessage({ defaultMessage: 'Week with more than 50 working hours ({hours}h) ({start} - {end})' }, { hours: this.decimalize(nbWorkHoursPerWeek, 2), start: dateFrom.clone().subtract(6, "days").format(getFormat('DATE')), end: dateFrom.format(getFormat('DATE')) })}`
                        };

                        if (errors.find(e => e.date === newError.date) === undefined ||
                            errors.find(e => e.date === newError.date && e.code === newError.code) === undefined) {
                            errors.push(newError);
                        }
                    }
                    nbWorkHoursPerWeek = 0;
                    nbRestDaysPerWeek = 0;
                }
            });


            //counting amount of maladie/maternité
            const maternitySickness = monthM + monthMa;



            //Ccnt algoritme per category
            //Work ---------------------------------------------------------------------------
            //temps effectif 
            //var workDone: any = (Math.round(monthTTime * 100) / 100).toFixed(2);
            const workDone = monthTTime;

            //Temps théorique
            const theoreticalWorkTime = (((usedDays) - (monthV + maternitySickness + monthA + monthMi)) / 7) * workHoursPerWeek - ((monthF + monthD) * (workHoursPerWeek / 5));

            //heures effectuée en +-
            const deltaWorkTime = workDone - theoreticalWorkTime;
            //Rest ---------------------------------------------------------------------------
            //Repos perçu
            const restRecieved = monthX;

            //repos à perçevoir
            const restToGet = (((usedDays) - (monthV + maternitySickness + monthA + monthMi)) / 7) * 2;

            //repos en +-
            const deltaRest = restRecieved - restToGet;

            //public holidays ---------------------------------------------------------------------------
            //Férié perçu
            const publicHolidayRecieved = monthF;

            //Férié à perçevoir
            const publicHolidayToGet = (6 * (usedDays)) / 365;

            //Vacation ---------------------------------------------------------------------------
            //vacances perçues
            const vacationRecieved = monthV;

            //vacances à perçevoir
            const vacationToGet = (35 * (usedDays)) / 365;


            //pushing all data in their arrays
            //Arrays for every information needed
            arrWorkDone[idxMonth] = workDone;
            arrTheoreticalWork[idxMonth] = theoreticalWorkTime;
            arrDeltaWork[idxMonth] = deltaWorkTime;
            arrRestRecieved[idxMonth] = restRecieved;
            arrRestToGet[idxMonth] = restToGet;
            arrDeltaRest[idxMonth] = deltaRest;
            arrPublicHolidayRecieved[idxMonth] = publicHolidayRecieved;
            arrPublicHolidayToGet[idxMonth] = publicHolidayToGet;
            arrVacationRecieved[idxMonth] = vacationRecieved;
            arrVacationToGet[idxMonth] = vacationToGet;
            arrMaternitySickness[idxMonth] = maternitySickness;
            arrAccidents[idxMonth] = monthA;
            arrArmy[idxMonth] = monthMi;
            arrOther[idxMonth] = monthD;
        });

        //updating the current state
        this.setState({
            starrWorkDone: arrWorkDone,
            starrTheoreticalWork: arrTheoreticalWork,
            starrDeltaWork: arrDeltaWork,
            starrRestRecieved: arrRestRecieved,
            starrRestToGet: arrRestToGet,
            starrDeltaRest: arrDeltaRest,
            starrPublicHolidayRecieved: arrPublicHolidayRecieved,
            starrPublicHolidayToGet: arrPublicHolidayToGet,
            starrVacationRecieved: arrVacationRecieved,
            starrVacationToGet: arrVacationToGet,
            starrMaternitySickness: arrMaternitySickness,
            starrAccidents: arrAccidents,
            starrArmy: arrArmy,
            starrOther: arrOther,
            starrDayDataCells: arrDayDataCells
        });


        //function summing up the numbers in an array
        function sumUp(total: string, num: string) {
            return parseFloat(total) + parseFloat(num);
        }


        //summing up the datas of every month to get the yearly data
        const temptotWorkDone: number = parseFloat((arrWorkDone.reduce(sumUp, 0)).toFixed(2));
        const temptotTheoreticalWork: number = parseFloat((arrTheoreticalWork.reduce(sumUp, 0)).toFixed(2));
        const temptotDeltaWork: number = parseFloat((arrDeltaWork.reduce(sumUp, 0)).toFixed(2));
        const temptotRestRecieved: number = parseFloat((arrRestRecieved.reduce(sumUp, 0)).toFixed(2));
        const temptotRestToGet: number = parseFloat((arrRestToGet.reduce(sumUp, 0)).toFixed(2));
        const temptotDeltaTest: number = parseFloat((arrDeltaRest.reduce(sumUp, 0)).toFixed(2));
        const temptotPublicHolidayRecieved: number = parseFloat((arrPublicHolidayRecieved.reduce(sumUp, 0)).toFixed(2));
        const temptotPublicHolidayToGet: number = parseFloat((arrPublicHolidayToGet.reduce(sumUp, 0)).toFixed(2));
        const temptotVacationRecieved: number = parseFloat((arrVacationRecieved.reduce(sumUp, 0)).toFixed(2));
        const temptotVacationToGet: number = parseFloat((arrVacationToGet.reduce(sumUp, 0)).toFixed(2));
        const temptotMaternitySickness: number = parseFloat((arrMaternitySickness.reduce(sumUp, 0)).toFixed(2));
        const temptotAccidents: number = parseFloat((arrAccidents.reduce(sumUp, 0)).toFixed(2));
        const temptotArmy: number = parseFloat((arrArmy.reduce(sumUp, 0)).toFixed(2));
        const temptotOther: number = parseFloat((arrOther.reduce(sumUp, 0)).toFixed(2));
        const temptotPaidDays: number = monthPaidHours.reduce(function (a, b) { return a + b; });

        // calculating the final balance data 
        const tempbalanceWorkHours = parseFloat((lastYearsWorkHours + temptotDeltaWork - temptotPaidDays).toFixed(2));
        const tempbalanceRest = parseFloat((lastYearsRest + (temptotRestToGet - temptotRestRecieved) - paidRest).toFixed(2));
        const tempbalanceVacation = parseFloat((lastYearsVacation + (temptotVacationToGet - temptotVacationRecieved) - paidVacation).toFixed(2));
        const tempbalancePublicHolidays = parseFloat((lastYearsPublicHoliday + (temptotPublicHolidayToGet - temptotPublicHolidayRecieved) - paidPublicHolidays).toFixed(2));

        //updating current state
        this.setState({
            totWorkDone: temptotWorkDone, totTheoreticalWork: temptotTheoreticalWork, totDeltaWork: temptotDeltaWork,
            totRestRecieved: temptotRestRecieved, totRestToGet: temptotRestToGet, totDeltaTest: temptotDeltaTest,
            totPublicHolidayRecieved: temptotPublicHolidayRecieved, totPublicHolidayToGet: temptotPublicHolidayToGet,
            totVacationRecieved: temptotVacationRecieved, totVacationToGet: temptotVacationToGet, totMaternitySickness: temptotMaternitySickness,
            totAccidents: temptotAccidents, totArmy: temptotArmy, totOther: temptotOther, totPaidDays: temptotPaidDays,
            balanceWorkHours: tempbalanceWorkHours, balanceRest: tempbalanceRest, balanceVacation: tempbalanceVacation,
            balancePublicHolidays: tempbalancePublicHolidays, AllErrors: errors
        });
    };

    //force an number to a given number of decimals || for display purpose
    decimalize(numberToDecimalize: number, nbDecimals = 2) {
        return (Math.round(numberToDecimalize * 100) / 100).toFixed(nbDecimals);
    }

    //creats the table for each month of the Ccnt sheet
    createDaysData = () => {
        const rows: ReactNode[] = [];
        const { starrDayDataCells } = this.state;

        starrDayDataCells.filter((v, i) => i !== 12).forEach((month, monthIdx) => {
            const columns: ReactNode[] = [];

            // Part 1/2 : Hours per day (left side of table)
            month.forEach((day, dayIdx) => {
                let tdClasseName = "";
                let column: ReactNode = <td></td>;

                if (dayIdx === 0) {
                    // Column of month number
                    column = <td className='tableCell' style={{ fontWeight: "bold" }} key={`monthIdx-${monthIdx}-${dayIdx}`}>{monthIdx + 1}</td>;
                } else if (dayIdx !== 0) {
                    // Design for days 29, 30, 31
                    if (monthIdx === 1 && dayIdx === 30) {
                        tdClasseName += ' tableCellBlack';
                    } else if (monthIdx === 1 && dayIdx === 29) {
                        tdClasseName += ' tableCellGray';
                    } else if ([1, 3, 5, 8, 10].includes(monthIdx) && dayIdx === 31) {
                        tdClasseName += ' tableCellBlack';
                    }

                    // Parse errors
                    const parsedDate = moment(`${dayIdx}/${monthIdx + 1}/${this.state.sttargetYear}`, "DD/MM/YYYY").format(MOMENT_CCNT_DAY_FORMAT);
                    const dailyErrors = this.state.AllErrors.filter(function getDailyErrors(error: CcntError) {
                        return error.date === parsedDate;
                    });

                    //setting up the errors classe
                    //if they are multipe errors an multple error class is used
                    //if they are multiple errors and on of them is an error type it will be shown as an error class
                    let errorText = "";
                    if (dailyErrors.length === 1) {
                        errorText += `${dailyErrors[0].message}  `;
                        tdClasseName = `ccnt-table-${dailyErrors[0].type}`;
                    }
                    if (dailyErrors.length > 1) {
                        if (dailyErrors.filter(de => de.type === CcntTypeErrors.Error).length > 0) {
                            tdClasseName += `ccnt-table-${CcntTypeErrors.Error}`;
                        } else {
                            tdClasseName += "ccnt-table-multiple-errors";
                        }
                        dailyErrors.forEach(de => {
                            if (de === dailyErrors[0]) {
                                errorText += `${de.message}`;
                            } else {
                                errorText += `<hr>${de.message}`;
                            }
                        });
                    }

                    // Hours per day of month, with errors
                    column = (
                        <td className={`${tdClasseName} dataCell ${dayIdx % 2 === 0 ? ' tableCellEven' : ' tableCell'}`} key={`tableTextLetter-and-tableTextNumber-${monthIdx}-${dayIdx}`}>
                            {
                                dailyErrors.length > 0 ?

                                    <Tooltip title={<div dangerouslySetInnerHTML={{ __html: errorText }}></div>}>
                                        <p className=' tableTextLetter' key={`tableTextLetter-${monthIdx}-${dayIdx}`}>{day[0]}</p>
                                        <p className=' tableTextNumber' key={`tableTextNumber-${monthIdx}-${dayIdx}`}>{day[1]}</p>
                                    </Tooltip>
                                    :
                                    <>
                                        <p className=' tableTextLetter' key={`tableTextLetter-${monthIdx}-${dayIdx}`}>{day[0]}</p>
                                        <p className=' tableTextNumber' key={`tableTextNumber-${monthIdx}-${dayIdx}`}>{day[1]}</p>
                                    </>
                            }
                        </td>
                    );
                }
                columns.push(column);

            });

            // Part 2/2 : Resume of month (right side of table)
            columns.push([
                <td key={`starrWorkDone-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(this.state.starrWorkDone[monthIdx])}</p></td>,
                <td key={`starrTheoreticalWork-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(this.state.starrTheoreticalWork[monthIdx])}</p></td>,
                <td key={`starrDeltaWork-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(this.state.starrDeltaWork[monthIdx])}</p></td>,
                <td key={`starrPaidHours-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(this.state.starrPaidHours[monthIdx])}</p></td>,
                <td key={`starrRestRecieved-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(this.state.starrRestRecieved[monthIdx])}</p></td>,
                <td key={`starrRestToGet-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(this.state.starrRestToGet[monthIdx])}</p></td>,
                <td key={`starrDeltaRest-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(this.state.starrDeltaRest[monthIdx])}</p></td>,
                <td key={`starrPublicHolidayRecieved-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(this.state.starrPublicHolidayRecieved[monthIdx])}</p></td>,
                <td key={`starrPublicHolidayToGet-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(this.state.starrPublicHolidayToGet[monthIdx])}</p></td>,
                <td key={`starrVacationRecieved-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(this.state.starrVacationRecieved[monthIdx])}</p></td>,
                <td key={`starrVacationToGet-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(this.state.starrVacationToGet[monthIdx])}</p></td>,
                <td key={`starrMaternitySickness-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(this.state.starrMaternitySickness[monthIdx])}</p></td>,
                <td key={`starrAccidents-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(this.state.starrAccidents[monthIdx])}</p></td>,
                <td key={`starrArmy-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(this.state.starrArmy[monthIdx])}</p></td>,
                <td key={`starrOther-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(this.state.starrOther[monthIdx])}</p></td>,
                <td key={`rightSignCell-${monthIdx}`} className='rightSignCell'><p></p></td>
            ]);

            rows.push(<tr className='tableRow' key={`monthidx-${monthIdx}`}>{columns}</tr>);
        });
        return rows;
    };

    componentDidMount() {
        this.calculateData();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.ccnt?.employee !== this.props.ccnt?.employee || prevProps.ccnt?.weeklyPlannedHours !== this.props.ccnt?.weeklyPlannedHours ||
            !isEqual(this.state.allHolidays, prevState.allHolidays) || !isEqual(this.state.increasedhoursByDay, prevState.increasedhoursByDay)) {
            this.calculateData();
        }
    }

    render() {
        const { starrDayDataCells, stEmployee } = this.state;
        const { toPrint } = this.props;
        return (
            <div id="ccntBody">
                {/* ---------- Ccnt Header ----------*/}
                {
                    toPrint &&
                    <Row gutter={0} >
                        <Col xs={{ span: 8 }} className="gutter-row" >
                            <p><FormattedMessage defaultMessage={'CCNT'} description={'CCNT'} /></p>
                            <p><FormattedMessage defaultMessage={'Convention collective nationale de travail pour les hôtels, restaurants et cafés'} description={'CCNT'} /></p>
                        </Col>
                        <Col xs={{ span: 8 }} className="gutter-row" >
                            <p style={{ textAlign: 'center' }}>Dufourstrasse 23</p>
                            <p style={{ textAlign: 'center' }}><FormattedMessage defaultMessage={'Case postale 357 - 4010 Bâle'} description={'CCNT'} /></p>
                        </Col>
                        <Col xs={{ span: 8 }} className="gutter-row" >
                            <p style={{ textAlign: 'right' }}>Vers.INF.FR 01.21</p>
                        </Col>
                    </Row>
                }
                <Row gutter={0}>
                    {/* ---------- Main Table ---------- */}
                    <table className='topTable'>
                        <tbody>
                            <tr>
                                <td rowSpan={2} colSpan={9}>
                                    <h1><FormattedMessage defaultMessage={'Saisie du temps'} description={'CCNT'} /><br>
                                    </br><FormattedMessage defaultMessage={'de travail CCNT'} description={'CCNT'} /></h1>
                                    <h3><FormattedMessage defaultMessage={'(art. 15 et art. 21)'} description={'CCNT'} /></h3>
                                </td>

                                <td colSpan={9} className='topTableTitleCell'>
                                    <p className='cellTitle'><FormattedMessage defaultMessage={'Année de travail'} description={'CCNT'} />{' *'}</p>
                                    <p className='cellInformationText'> <strong>{this.state.sttargetYear} </strong></p>
                                </td>
                                <td colSpan={14} className='topTableTitleCell'>
                                    <p className='cellTitle'><FormattedMessage defaultMessage={'Etablissement'} description={'CCNT'} />{' *'}</p>
                                    <p className='cellInformationText' > <strong>{this.state.stCompany} </strong></p>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Temps effectif'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTopTitle'><FormattedMessage defaultMessage={'Temps théorique'} description={'CCNT'} /></span>
                                    <span className='rotatedTitle rotatedsubTitle'><FormattedMessage defaultMessage={'art. 15 CCNT'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Heures effectuées en +/-'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Heures payées'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Repos perçu'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTopTitle'><FormattedMessage defaultMessage={'Repos à percevoir'} description={'CCNT'} /></span>
                                    <span className='rotatedTitle rotatedsubTitle'><FormattedMessage defaultMessage={'art. 16 CCNT'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Repos en +/-'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Fériés perçus'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTopTitle'><FormattedMessage defaultMessage={'Fériés à percevoir'} description={'CCNT'} /></span>
                                    <span className='rotatedTitle rotatedsubTitle'><FormattedMessage defaultMessage={'art. 18 CCNT'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Vacances perçues'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTopTitle'><FormattedMessage defaultMessage={'Vacances à percevoir'} description={'CCNT'} /></span>
                                    <span className='rotatedTitle rotatedsubTitle'><FormattedMessage defaultMessage={'art. 17 CCNT'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={2}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Maladie / Maternité'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={2}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Accident'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={2}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Militaire'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={2}>
                                    <span className='rotatedTitle RTSDivers'><FormattedMessage defaultMessage={'Divers'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom topRightCellSignature' rowSpan={2}>
                                    <span className='rotatedTitle RTSSignature'><FormattedMessage defaultMessage={'Signature'} description={'CCNT'} /></span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={9} className='topTableTitleCell'>
                                    <p className='cellTitle'><FormattedMessage defaultMessage={'Collaborateur'} description={'CCNT'} />{' *'}</p>
                                    <p className='cellInformationText'><strong>{stEmployee}</strong></p>
                                </td>
                                <td colSpan={4} className='topTableTitleCell'>
                                    <p className='cellTitle'><FormattedMessage defaultMessage={"Date d'entrée"} description={'CCNT'} />{' *'}</p>
                                    <p className='cellInformationText'><strong>{this.state.stEntryDate}</strong></p>
                                </td>
                                <td colSpan={4} className='topTableTitleCell'>
                                    <p className='cellTitle'><FormattedMessage defaultMessage={'Date de sortie'} description={'CCNT'} />{' *'}</p>
                                    <p className='cellInformationText'><strong>{this.state.stExitDate}</strong></p>
                                </td>
                                <td className='standartCellCenter align-left' colSpan={4}>
                                    <h4><FormattedMessage defaultMessage={'Durée hebdomadaire'} description={'CCNT'} /><br /><FormattedMessage defaultMessage={'de travail'} description={'CCNT'} />{' *'}</h4>
                                    <img src={Arrow} className='arrowPngWorkPerWeek' alt=''></img>
                                </td>
                                <td className='hoursInputCell' colSpan={2}>
                                    <p><strong>{this.decimalize(this.state.stWorkHoursPerWeek)}</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td className='firsttableCell'>
                                    <p><FormattedMessage defaultMessage={'Jour'} description={'CCNT'} /></p>
                                </td>
                                {
                                    // Column of day number
                                    starrDayDataCells[0].filter((v, i) => i !== 0).map((day, dayIdx) => {
                                        return <td key={`day-number-${dayIdx}`} rowSpan={2} className={dayIdx % 2 === 0 ? 'tableCell' : 'tableCellEven'} style={{ fontWeight: 'bold' }}>{dayIdx + 1}</td>;
                                    })
                                }
                                <td colSpan={5} rowSpan={2} className='specialCellGray'>
                                    <p className={this.state.stCcntWarningDefalutClass}>
                                        <FormattedMessage defaultMessage={'ATTENTION: Les 1/2 jours de repos et les'} description={'CCNT'} />
                                        <br /> <FormattedMessage defaultMessage={'1/2 jours fériés en rouge ne sont pas'} description={'CCNT'} />
                                        <br /> <FormattedMessage defaultMessage={'correctement perçus (+5h00 de travail) et'} description={'CCNT'} />
                                        <br /> <FormattedMessage defaultMessage={'ne sont comptabilisés'} description={'CCNT'} />
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ borderTop: '1px solid black' }}>
                                <td className='firsttableCell'>
                                    <p><FormattedMessage defaultMessage={'Mois'} description={'CCNT'} /></p>
                                </td>
                                <td colSpan={3} className='specialCell'>
                                    <img src={Arrow} className='arrowPngHours' alt=''></img>
                                    <p className='specialText'><FormattedMessage defaultMessage={'Solde heures'} description={'CCNT'} /><br></br><FormattedMessage defaultMessage={'année précédente'} description={'CCNT'} /></p>
                                </td>
                                <td className='specialCell'>
                                    <p className='specialTextbig'>{this.decimalize(this.state.stlastYearsWorkHours)}</p>
                                </td>
                                <td colSpan={2} className='specialCell'>
                                    <img src={Arrow} className='arrowPngRest' alt=''></img>
                                    <p className='specialText'><FormattedMessage defaultMessage={'Solde année'} description={'CCNT'} /><br></br><FormattedMessage defaultMessage={'précédente'} description={'CCNT'} /></p>
                                </td>
                                <td className='specialCell'>
                                    <p className='specialTextbig'>{this.decimalize(this.state.stlastYearsRest)}</p>
                                </td>
                                <td className='specialCell'>
                                    <p className='specialTextbig'>{this.decimalize(this.state.stlastYearsPublicHoliday)}</p>
                                </td>
                                <td className='specialCellBlack'>
                                    <p className='specialText'></p>
                                </td>
                                <td className='specialCell'>
                                    <p className='specialTextbig'>{this.decimalize(this.state.stlastYearsVacation)}</p>
                                </td>
                                <td className='specialCellBlack'>
                                </td>
                            </tr>
                            {/* ---------- Main Table / left part, data for every day---------- */}
                            {this.createDaysData()}
                            {/* ---------- Main Table / right side, monthly summary of the data and result of monthly calculations ---------- */}
                            <tr style={{ border: 'none' }}>
                                <td className='emptycolumnTable' colSpan={32}>
                                    <p className='totalTitleParagraph'><FormattedMessage defaultMessage={'Total'} description={'CCNT'} /></p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(this.state.totWorkDone)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(this.state.totTheoreticalWork)}</p>
                                </td>
                                <td className='specialCellBlack'>
                                    <p></p>
                                </td>
                                <td className='specialCellBlack'>
                                    <p></p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(this.state.totRestRecieved)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(this.state.totRestToGet)}</p>
                                </td>
                                <td className='specialCellBlack'>
                                    <p></p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(this.state.totPublicHolidayRecieved)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(this.state.totPublicHolidayToGet)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(this.state.totVacationRecieved)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(this.state.totVacationToGet)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(this.state.totMaternitySickness)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(this.state.totAccidents)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(this.state.totArmy)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(this.state.totOther)}</p>
                                </td>
                                <td className='emptycolumnTable'></td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <Row gutter={0}>
                    <Col className="gutter-row" span={200}>
                        {/* ---------- bottom table, left, legend and signature ---------- */}
                        <div >
                            <table className='legendTable'>
                                <tbody>
                                    <tr>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'T'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Travail'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'X'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Repos'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'F'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Férié'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'V'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Vacances'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'Mi'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Militaire'} description={'CCNT'} /></p>
                                        </td>
                                    </tr>
                                    <tr><td><br></br></td></tr>
                                    <tr>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'M'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Maladie'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'A'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Accident'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'Ma'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Maternité / paternité'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'D'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td colSpan={3} className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Divers'} description={'CCNT'} /> <span className='legendSubDescription'><FormattedMessage defaultMessage={'(congé de formation art.19 CCNT et congé payé art. 20 CCNT)'} description={'CCNT'} /></span></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className='signatureTable'>
                                <tbody>
                                    <tr className='signatreRow'>
                                        <td className='signatureCell'>
                                            <p><FormattedMessage defaultMessage={"Signatures en fin d'année ou fin de période"} description={'CCNT'} />{':'}</p>
                                            <p></p>
                                        </td>
                                        <td className='signatureCell'>
                                            <p><FormattedMessage defaultMessage={'Signature employeur'} description={'CCNT'} /></p>
                                            <div></div>
                                        </td>
                                        <td className='signatureCell'>
                                            <p><FormattedMessage defaultMessage={'Signature collaborateur'} description={'CCNT'} /></p>
                                            <div></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={200}>
                        {/* ---------- bottom table, right, yearly summary and calculations ---------- */}
                        <div >
                            <table className='totalTable'>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p></p>
                                        </td>
                                        <td className='totalFineCells'>
                                            <p><FormattedMessage defaultMessage={'Temps effectif'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalFineCells'>
                                            <p><FormattedMessage defaultMessage={'Temps théorique'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalFineCells'>
                                            <p><FormattedMessage defaultMessage={'Heures payées'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalFineCells'>
                                            <p><FormattedMessage defaultMessage={'Solde heures'} description={'CCNT'} /></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='totalThickCells'>
                                            <p><FormattedMessage defaultMessage={'Heures'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(this.state.totWorkDone)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(this.state.totTheoreticalWork)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(this.state.totPaidDays)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(this.state.balanceWorkHours)}</p>
                                        </td>
                                        <td className='totalLegendCells'>
                                            <p>{this.state.balanceWorkHours > 0 ? <FormattedMessage defaultMessage={'heure(s) supplémentaires'} description={'CCNT'} /> : this.state.balanceWorkHours < 0 ? <FormattedMessage defaultMessage={'heure(s) en négatif(s)'} description={'CCNT'} /> : ""}</p>
                                        </td>
                                    </tr>
                                    <tr><td className='totalSeparatorCell'></td></tr>
                                    <tr>
                                        <td>
                                            <p></p>
                                        </td>
                                        <td className='totalFineCells'>
                                            <p><FormattedMessage defaultMessage={'Perçus'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalFineCells'>
                                            <p><FormattedMessage defaultMessage={'Droit'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalFineCells'>
                                            <p><FormattedMessage defaultMessage={'Jours payé'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalFineCells'>
                                            <p><FormattedMessage defaultMessage={'Soldes jours'} description={'CCNT'} /></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='totalThickCells'>
                                            <p><FormattedMessage defaultMessage={'Repos'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(this.state.totRestRecieved)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(this.state.totRestToGet)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(this.state.stpaidRest)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(this.state.balanceRest)}</p>
                                        </td>
                                        <td className='totalLegendCells'>
                                            <p>{this.state.balanceRest > 0 ? <FormattedMessage defaultMessage={'repos à prendre/indemniser'} description={'CCNT'} /> : this.state.balanceRest < 0 ? <FormattedMessage defaultMessage={'repos accordé en sus'} description={'CCNT'} /> : ""}</p>
                                        </td>
                                    </tr>
                                    <tr><td className='totalSeparatorCell'></td></tr>
                                    <tr>
                                        <td className='totalThickCells'>
                                            <p><FormattedMessage defaultMessage={'Vacances'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(this.state.totVacationRecieved)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(this.state.totVacationToGet)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(this.state.stpaidVacation)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(this.state.balanceVacation)}</p>
                                        </td>
                                        <td className='totalLegendCells'>
                                            <p>{this.state.balanceVacation > 0 ? <FormattedMessage defaultMessage={'vacances à prendre/indemniser'} description={'CCNT'} /> : this.state.balanceVacation < 0 ? <FormattedMessage defaultMessage={' perçus en trop'} description={'CCNT'} /> : ""}</p>
                                        </td>
                                    </tr>
                                    <tr><td className='totalSeparatorCell'></td></tr>
                                    <tr>
                                        <td className='totalThickCells'>
                                            <p><FormattedMessage defaultMessage={'Fériés'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(this.state.totPublicHolidayRecieved)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(this.state.totPublicHolidayToGet)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(this.state.stpaidPublicHolidays)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(this.state.balancePublicHolidays)}</p>
                                        </td>
                                        <td className='totalLegendCells'>
                                            <p>{this.state.balancePublicHolidays > 0 ? <FormattedMessage defaultMessage={'fériés à prendre/indemniser'} description={'CCNT'} /> : this.state.balancePublicHolidays < 0 ? <FormattedMessage defaultMessage={'fériés perçus en trop'} description={'CCNT'} /> : ""}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </div>
        );

    }
}

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
});
const connector = connect(mapStateToProps);

export default connector(injectIntl(Ccnt));
import axios from "axios";
import Cookie from 'js-cookie';
// import { getDomain } from "../resources/utils";

let Token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjMxMTkzMzQ2LCJqdGkiOiJhNGUzNmY3MWNlMDI0ZmVlYjNhY2MzMzkzMTExOGM1MSIsInVzZXJfaWQiOjJ9.6au_KR08SBERdlNfSrQ9Ygcwl3rugdumyvv8piZ60EA";

let API = process.env.REACT_APP_BACK_PRODUCT_URL;
if (!API) {
    API = "https://product.swisskischool.ch";
}
// const API = "https://product.swisskischool.ch";
// const API = "http://localhost:8000";
// const API = "http://swissskischool.ch:8000";


if (Cookie.get('authentication')) {
    Token = JSON.parse(Cookie.get("authentication"));
}

const getDomain = () => {
    return "sunkhronos.ch"
}

const axiosapi = axios.create({
    baseURL: API,
    timeout: 40000,
    contentType: "applicationCache/json",
});
axiosapi.defaults.headers.common["Authorization"] = `Bearer ${Token}`;

axiosapi.interceptors.response.use(response => {
    return response;
},
error => {
    const {
        config,
        response
    } = error;
    let status, data = null;
    if (response && response.status) status = response.status;
    if (response && response.data) data = response.data;

    if (status && status === 401 && (data.code === "bad_authorization_header" || data.code === "token_not_valid")) {
        // TODO: HASH PASSWORD AND TOKEN IN LOCALSTORAGE
        // Login webadmin to API
        return axiosapi.post(API + '/api/token', {
            "username": "front",
            "password": "gE6WU8kA"
        }).then(response => {
            const accessToken = response.data.access;
            Cookie.set('authentication', JSON.stringify(accessToken), { domain: getDomain() });
            // update config and axiosapi Authorization header
            config.headers.Authorization = 'Bearer ' + accessToken;
            axiosapi.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
            return axiosapi.request(config);
        });
    }
    return Promise.reject(error)
}
);

export default axiosapi;

import { UploadOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import isArray from 'lodash/isArray';
import { Moment } from 'moment';
import React, { CSSProperties } from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { GOOGLE_STORAGE_URL } from '../../../utils/constants';
import { GenericFile } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import Carousel from '../general/carousel';

type ReduxProps = ConnectedProps<typeof connector>
interface Props extends ReduxProps, IntlProps {
    onUploaded(files: (File | string | GenericFile)[] | undefined): void;
    files?: (File | string | GenericFile)[];
    className?: string;
    containerStyle?: CSSProperties;
    editText?: string;
    addText?: string;
    deleteTextMaxWidth?: number
    loading?: boolean;
    max?: number;

}

interface State {
    isBucketProtected: boolean;
    bucketName?: string;
    filesName?: string[];
    signedUrl?: string[];
    bucketProtectionExpiration?: Moment;
    files?: {
        id: number;
        name: string;
        image: string;
        fullImageUrl: string;
        src: string;
        file: File | string | GenericFile;
        active: boolean;
        created?: Moment;
        modified?: Moment;
    }[];
}

/**
 * Component that represent an image uploader field
 */
class FileUploaderMultiple extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isBucketProtected: false
        }
    }
    /**
     * Check that the file is less than 2MB
     * @param file the uploaded file
     * @return true if the file is less than 2MB
     */
    componentDidMount(): void {
        this.setFileFromProps()
    }
    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (!isEqual(prevProps.files, this.props.files)) {
            this.setFileFromProps()
        }
    }

    toBase64 = (file: File | Blob) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString() || '');
        reader.onerror = reject;
    });

    setFileFromProps = async () => {
        console.log("COUCOU START SET FILES FROM PROPS", this.props.files)
        const files: {
            id: number;
            name: string;
            image: string;
            fullImageUrl: string;
            src: string;
            file: File | string | GenericFile;
            active: boolean;
            created?: Moment;
            modified?: Moment;
        }[] = []
        if (this.props.files) {
            for (let i = 0; i < this.props.files.length; i++) {
                let name = 'image'
                let image = 'image'
                let src = 'nan'

                const file = this.props.files[i]
                if (file && typeof file === 'string') {

                    if (file.startsWith('sunkhronos_admin/')) {
                        const splittedFile = file.split('/')
                        if (splittedFile.length > 1) {
                            name = splittedFile[1]
                        }
                    }
                    image = file
                    src = file.startsWith("http") ? file : GOOGLE_STORAGE_URL + file
                } else if (file && file instanceof File) {
                    name = file.name
                    image = file.name

                    await this.toBase64(file).then(
                        (fileSrc: string) => {
                            src = fileSrc
                        }
                    )
                } else if (file && typeof file !== 'string' && !(file instanceof File)) {
                    name = file.file_name
                    image = file.file_name
                    src = file.auth_url
                }
                files.push({
                    id: i + 1, name, src, fullImageUrl: src, file, active: true, image
                })
            }
            console.log("COUCOU SEND ET FILES FROM PROPS", files)

            this.setState({ files })
        }
    }

    checkFile = (file: File): boolean => {
        const isLessThan20MB = file.size / 1024 / 1024 <= 20;

        if (!isLessThan20MB) showNotification(this.props.intl.formatMessage({ defaultMessage: 'The file must be less than {size}MB.' }, { size: 20 }), "warning");

        return isLessThan20MB;
    }

    /**
     * Call after that the image is uploaded
     * @param event event the triggered event
     */
    uploadFile = (event: any): void => {
        const files: File[] = Array.from(event.target.files)
        const max = this.props.max !== undefined ? this.props.files !== undefined ? (this.props.max - this.props.files.length) : this.props.max : undefined

        if (max !== undefined && files.length > max) {
            showNotification(`${this.props.intl.formatMessage({ defaultMessage: 'The maximum file limit is {limit}' }, { limit: this.props.max })}\n${max > 0 ? this.props.intl.formatMessage({ defaultMessage: 'You can still upload {max} files' }, { max }) : this.props.intl.formatMessage({ defaultMessage: 'You can no longer upload files.' })}`, 'warning')
            return
        }

        if (files && files.length > 0 && files.every((file: File) => this.checkFile(file))) {
            const f: (string | File | GenericFile)[] = this.props.files && isArray(this.props.files) ? this.props.files : []

            this.props.onUploaded([...f, ...files]);
        }
    };
    onChange = (event: any): void => {
        event.stopPropagation();
        event.preventDefault()
        this.uploadFile(event);
    }

    /**
     * Called when the user drag the file over the component
     * @param event event the triggered event
     */
    onDragOver = (event: any): void => {
        event.preventDefault();
        event.stopPropagation();

        const div = document.getElementById("file-uploader")!;
        if (div.className.includes("image-uploader-drag")) return;
        div.className += " image-uploader-drag";
    }

    /**
     * Called when the user drop the file (Drag and Drop)
     * @param event event the triggered event
     */
    onDrop = (event: any): void => {
        event.stopPropagation();
        const div = document.getElementById("file-uploader")!;
        div.className = div.className.replace("image-uploader-drag", "");
        this.uploadFile(event);
    }


    render() {
        const disabled = (this.props.files !== undefined && this.props.max !== undefined && this.props.files.length >= this.props.max)
        const max = this.props.max !== undefined ? this.props.files !== undefined ? (this.props.max - this.props.files.length) : this.props.max : undefined

        return (
            <div style={this.props.containerStyle}>
                {this.props.loading ? <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}><Spin size={'large'} spinning={this.props.loading} /></div>
                    : <div id="file-uploader" onDragOver={this.onDragOver} className={`file-uploader-parent${disabled ? '-disabled' : ''} ${this.props.className ? this.props.className : ''}`}>
                        <input disabled={disabled} style={disabled ? { cursor: 'default' } : {}} className="image-uploader-input" type="file" multiple max={max} accept={'.jpg,.jpeg,.png'} onChange={this.onChange} />
                        <div className="file-uploader-content file-uploader-button">
                            <UploadOutlined style={{ marginRight: '10px' }} /> {this.state.files && this.state.files.length > 0 ? this.props.editText ?? <FormattedMessage defaultMessage={'Edit files'} /> : this.props.addText ?? <FormattedMessage defaultMessage={'Add files'} />}
                        </div>
                    </div>}
                <div className="file-uploader-divider" />
                {this.state.files && this.state.files.length > 0 &&
                    <Carousel
                        leftText=''
                        rightText=''
                        images={this.state.files}
                        width={350}
                        height={150}
                        deleteImage={(id: number) => {
                            const file = this.state.files?.find(f => f.id === id)
                            file && this.props.onUploaded(this.props.files?.filter(f => f !== file.file))
                            this.setState({ files: this.state.files?.filter(f => f.id !== id) })
                        }}
                    />}
            </div>
        );
    }
}




const mapStateToProps = (state: ApplicationState) => ({
    width: state.window.width,
});

const connector = connect(mapStateToProps);

export default connector(injectIntl(FileUploaderMultiple));
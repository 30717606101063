import { Checkbox, Divider, message, Popconfirm, Select } from "antd";
import { cloneDeep } from "lodash";
import { ReactNode, useCallback, useMemo } from "react";
import { RiTimerFlashLine } from "react-icons/ri";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import useCompany from "../../../../hooks/useCompany";
import useDepartments from "../../../../hooks/useDepartments";
import useGroups from "../../../../hooks/useGroups";
import usePOIs from "../../../../hooks/usePOIs";
import useProjects from "../../../../hooks/useProjects";
import useStaffTypes from "../../../../hooks/useStaffTypes";
import useTypeOfDays from "../../../../hooks/useTypeOfDays";
import useTypeOfDaysOff from "../../../../hooks/useTypeOfDaysOff";
import { useUsersVisible } from "../../../../hooks/useUsers";
import { changeUsers } from "../../../../store/actions/teamManagement";
import { CaseType } from "../../../../utils/constants";
import Network from "../../../../utils/network";
import { DictionaryString, User } from "../../../../utils/types/generalTypes";
import { getCaseAndPlural, isNullOrEmpty, showNotification } from "../../../../utils/utils";
import FAIcon from "../../../common/FAIcon";
import CircleButton from "../../../common/fields/circleButton";
import Anticon from "../../../common/general/anticon";
const { Option } = Select;

interface Props {
    showEmpty?: {
        showEmpty: boolean;
        setShowEmpty: (show: boolean) => void;
    };
    showDayOff?: {
        showDayOff: boolean;
        setShowDayOff: (show: boolean) => void;
    };
    users?: {
        // allUserIds?: string[];
        selectedUsers: number[];
        changeUsers: (ids: number[]) => void;
    };

    groups?: {
        selectedGroups: number[];
        usersToExclude: number[];
        changeGroups: (ids: number[], userToExclude: number[]) => void;
    };

    typeOfDays?: {
        selectedTypeOfDays: number[];
        changeTypeOfDays: (ids: number[]) => void;
    };

    typeOfDaysOff?: {
        selectedTypeOfDaysOff: number[];
        changeTypeOfDaysOff: (ids: number[]) => void;
    };

    departments?: {
        selectedDepartments: number[];
        changeDepartments: (ids: number[]) => void;
    };

    projects?: {
        selectedProjects: number[];
        changeProjects: (ids: number[]) => void;
    };

    staffTypes?: {
        selectedStaffTypes: number[];
        changeStaffTypes: (ids: number[]) => void;
    };

    POIs?: {
        selectedPOIs: number[];
        changePOIs: (POIs: number[]) => void;
    };

    eventClockedStatus?: {
        eventClockedStatus: number[];
        changeEventClockedStatus: (id: number[]) => void;
    };

    confirmed?: {
        confirmed?: boolean;
        setConfirmed: (confirmed: boolean | undefined) => void;
    };



    reset: () => void;
}

const TimeClockControlFiltersOptions = [
    {
        value: 1,
        label: <FormattedMessage defaultMessage={'In progress'} />
    },
    {
        value: 2,
        label: <FormattedMessage defaultMessage={'Pending'} />
    },
    {
        value: 3,
        label: <FormattedMessage defaultMessage={'Not clocked'} />
    }
];



const Filters = (props: Props) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const company = useCompany();
    const typeOfDays = useTypeOfDays();
    const typeOfDaysOff = useTypeOfDaysOff();
    const users = useUsersVisible();
    const groups = useGroups();
    const POIs = usePOIs();
    const departments = useDepartments();
    const projects = useProjects();
    const staffTypes = useStaffTypes();
    const usersSorted = useMemo(() => users.sort((a, b) => `${a.last_name}${a.first_name}`.localeCompare(`${b.last_name}${b.first_name}`) ? 1 : -1), [users]);
    const usersForExclusion = useMemo(() => {
        if (props.groups) {
            const { selectedGroups, usersToExclude } = props.groups;
            let userIdsFromGroups: number[] = [];
            if (selectedGroups && selectedGroups.length > 0) {
                const userIdInSelectedGroups = selectedGroups.reduce((obj, group) => {
                    if (group)
                        obj[group] = [];
                    return obj;
                }, {} as DictionaryString<number[]>);
                for (const user of users)
                    if (user.group_users)
                        if (user.group_users.some(g => selectedGroups.includes(g.group)))
                            for (const groupUser of user.group_users)
                                if (groupUser.group in userIdInSelectedGroups)
                                    userIdInSelectedGroups[groupUser.group].push(user.id);

                userIdsFromGroups = Object.keys(userIdInSelectedGroups).reduce((userIds, groupId) => [...userIds, ...userIdInSelectedGroups[groupId]], [] as number[]);
            }
            return {
                usersAvailableForExclusion: users.filter(u => userIdsFromGroups.includes(u.id)),
                usersToExclude: usersToExclude?.filter(u => userIdsFromGroups.includes(u))
            };
        }
        return {
            usersAvailableForExclusion: [],
            usersToExclude: []
        };
    }, [props, users]);

    const hasEventsFilters = useMemo(() => {
        return (props.departments && !isNullOrEmpty(departments.data)) || (props.projects && !isNullOrEmpty(projects)) || props.typeOfDays || props.typeOfDaysOff || props.staffTypes || props.showDayOff || props.POIs || props.confirmed;
    }, [props.departments, departments, props.projects, projects, props.typeOfDays, props.typeOfDaysOff, props.staffTypes, props.showDayOff, props.POIs, props.confirmed]);

    const hasUsersFilters = useMemo(() => props.groups || props.users || props.showEmpty, [props.users, props.groups, props.showEmpty]);
    const hasBadgingFilters = useMemo(() => props.eventClockedStatus, [props.eventClockedStatus]);

    const onAddUsersConfirm = useCallback((usersId: number[], groupId: number) => {

        const group = groups.find(g => g.id === groupId);
        let processedUsers = 0;
        const successUsers: User[] = [];
        const errorUsers: User[] = [];

        usersId.forEach(userId => {
            Network.addUserToGroup(groupId, userId).then(
                () => {
                    processedUsers++;
                    const user = users?.find(u => userId === u.id);
                    user && successUsers.push(user);
                },
                () => {
                    processedUsers++;
                    const user = users?.find(u => userId === u.id);
                    user && errorUsers.push(user);
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while adding {user} to the group {group}' }, { user: `${user?.last_name} ${user?.first_name}`, group: group?.name }), "warning");
                }
            ).finally(() => {
                if (processedUsers === usersId.length) {
                    if (errorUsers.length > 0) {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while adding the user(s) {users} to the group {group}' }, { users: errorUsers.map(u => `${u.last_name} ${u.first_name}`).join(", "), group: group?.name }), "warning");
                    } else {
                        message.success(intl.formatMessage({ defaultMessage: 'The users have been successfully created to the group {group}' }, { group: group?.name }));
                    }

                    const tmpUsers = cloneDeep(users);

                    successUsers.forEach(successUser => {
                        const userIdx = tmpUsers?.findIndex(u => u.id === successUser.id);
                        if (tmpUsers && userIdx && userIdx >= -1) {
                            tmpUsers[userIdx].group_users?.push({
                                active: true,
                                group: groupId,
                                group_name: group?.name,
                                id: -1,
                                is_admin: false,
                            });
                        }
                    });
                    dispatch(changeUsers(tmpUsers));
                    props.users?.changeUsers(errorUsers.length === 0 ? [] : errorUsers.map(eu => eu.id));
                }
            });
        });
    }, [dispatch, groups, intl, props.users, users]);

    // #region Users
    const userFilter = <Filter
        title={<FormattedMessage defaultMessage={'Users'} />}
        input={
            <Select
                className="__filter-users-group-select"
                listHeight={500}
                mode="multiple"
                allowClear
                // placeholder={<FormattedMessage defaultMessage={'Users to display'} />}
                style={{ flex: 1 }}
                value={props.users?.selectedUsers}
                onChange={props.users?.changeUsers}
                filterOption={true}
                optionFilterProp="label"
                showArrow
                maxTagCount={"responsive"}
            >
                {
                    usersSorted.map(user => (<Option
                        value={user.id}
                        key={"users-" + user.id}
                        label={`${user.last_name} ${user.first_name}`}
                    >
                        {user.last_name} {user.first_name}
                    </Option>
                    ))
                }
            </Select>}
        extra={
            <>
                {
                    props.users?.selectedUsers && props.users.selectedUsers.length > 0 && props.groups?.selectedGroups && props.groups.selectedGroups.length == 1 ?
                        <Popconfirm
                            placement="topRight"
                            title={<FormattedMessage defaultMessage={'{count, plural, one {Add this user to the group} other {Add these users to the group}}'} values={{ count: props.users?.selectedUsers?.length }} />}
                            onConfirm={() => props.users?.selectedUsers && props.groups?.selectedGroups && onAddUsersConfirm(props.users?.selectedUsers, props.groups?.selectedGroups[0])}
                            okText={<FormattedMessage defaultMessage={'Continue'} />}
                            cancelText={<FormattedMessage defaultMessage={'Cancel'} />}>
                            <CircleButton
                                icon={<FAIcon name='merge' prefix='far' />}
                                small
                                title={intl.formatMessage({ defaultMessage: '{count, plural, one {Add this user to the following group} other {Add these users to the following group}}' }, { count: props.users?.selectedUsers?.length })}
                            />
                        </Popconfirm>
                        :
                        undefined
                }
            </>
        }
    />;
    // #endregion

    // #region Groups
    const groupFilter = <Filter title={<FormattedMessage defaultMessage={'Groups'} />} input={
        <Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            // placeholder={<FormattedMessage defaultMessage={'Groups to display'} />}
            style={{ width: '100%' }}
            value={props.groups?.selectedGroups}
            onChange={(groups) => props.groups && props.groups.changeGroups && props.groups.usersToExclude && props.groups.changeGroups(groups, props.groups.usersToExclude)}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                groups.map(group => (<Option
                    value={group.id}
                    key={"groups-" + group.id}
                    label={`${group.name}`}
                >
                    {group.name}
                </Option>
                ))
            }
        </Select>}
    />;

    const excludeFilter = <Filter title={<FormattedMessage defaultMessage={'Exclude'} />}
        input={<Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            // placeholder={<FormattedMessage defaultMessage={'Users to exclude'} />}
            style={{ width: '100%' }}
            value={usersForExclusion.usersToExclude}
            onChange={(exclude) => props.groups && props.groups.changeGroups && props.groups.selectedGroups && props.groups.changeGroups(props.groups.selectedGroups, exclude)}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                usersForExclusion.usersAvailableForExclusion.map(user => (<Option
                    value={user.id}
                    key={"users-to-exclude-" + user.id}
                    label={`${user.last_name} ${user.first_name}`}
                >
                    {`${user.last_name} ${user.first_name}`}
                </Option>
                ))
            }
        </Select>}
    />;
    // #endregion

    // #region Type of days
    const typeOfDaysFilter = <Filter title={<FormattedMessage defaultMessage={'Type of days'} />} input={
        <Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            // placeholder={<FormattedMessage defaultMessage={'Types of day to display'} />}
            style={{ width: '100%' }}
            value={props.typeOfDays?.selectedTypeOfDays}
            onChange={props.typeOfDays?.changeTypeOfDays}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                typeOfDays.map(t => (<Option
                    value={t.id}
                    key={"tod-" + t.id}
                    label={`${t.title}`}
                >
                    {t.title}
                </Option>
                ))
            }
        </Select>}
    />;
    // #endregion

    // #region Type of days off
    const typeOfDaysOffFilter = <Filter title={<FormattedMessage defaultMessage={'Types of day off'} />} input={
        <Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            disabled={props.showDayOff?.showDayOff}
            // placeholder={<FormattedMessage defaultMessage={'Types of day to display'} />}
            style={{ width: '100%' }}
            value={props.typeOfDaysOff?.selectedTypeOfDaysOff}
            onChange={props.typeOfDaysOff?.changeTypeOfDaysOff}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                typeOfDaysOff.map(t => (<Option
                    value={t.id}
                    key={"tod-" + t.id}
                    label={`${t.title}`}
                >
                    {t.title}
                </Option>
                ))
            }
        </Select>}
    />;
    // #endregion

    // #region POIs
    const POIsFilter = POIs ? <Filter title={<FormattedMessage defaultMessage={'Points of interests'} />} input={
        <Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            // placeholder={<FormattedMessage defaultMessage={'Points of interest to display'} />}
            style={{ width: '100%' }}
            value={props.POIs?.selectedPOIs}
            onChange={props.POIs?.changePOIs}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                POIs.map(poi => (<Option
                    value={poi.id}
                    key={"poi-" + poi.id}
                    label={`${poi.title}`}
                >
                    {poi.title}
                </Option>
                ))
            }
        </Select>}
    /> : <></>;
    // #endregion

    // #region Confirmed
    const confirmedFilter = <Filter input={
        <div className="confirmed-filter">
            <span><FormattedMessage defaultMessage={'Events'} /></span>

            <div onClick={() => props.confirmed && props.confirmed.setConfirmed && props.confirmed.setConfirmed(props.confirmed.confirmed === undefined ? true : props.confirmed.confirmed ? false : undefined)}>
                <FAIcon prefix='fas' name='caret-left' />
                <span>{props.confirmed?.confirmed === undefined
                    ?
                    <FormattedMessage defaultMessage={'All'} />
                    : props.confirmed.confirmed
                        ? <FormattedMessage defaultMessage={'Confirmed'} />
                        : <FormattedMessage defaultMessage={'Not confirmed'} />
                }
                </span>
                <FAIcon prefix='fas' name='caret-right' />
            </div>
        </div>
    } />;
    // #endregion

    // #region Departments
    const departmentsFilter = <Filter title={<FormattedMessage defaultMessage={'Departments'} />} input={
        <Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            // placeholder={<FormattedMessage defaultMessage={'Departments to display'} />}
            style={{ width: '100%' }}
            value={props.departments?.selectedDepartments}
            onChange={props.departments?.changeDepartments}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                departments && departments.data && departments.data.map(d => (<Option
                    value={d.id}
                    key={"department-" + d.id}
                    label={`${d.name}`}
                >
                    {d.name}
                </Option>
                ))
            }
        </Select>
    } />;
    // #endregion

    // #region Departments
    const staffTypesFilter = <Filter title={<FormattedMessage defaultMessage={'Staff types'} />} input={
        <Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            // placeholder={<FormattedMessage defaultMessage={'Staff types to display'} />}
            style={{ width: '100%' }}
            value={props.staffTypes?.selectedStaffTypes}
            onChange={props.staffTypes?.changeStaffTypes}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                staffTypes && staffTypes.data?.map(s => (<Option
                    value={s.id}
                    key={"st-" + s.id}
                    label={`${s.name}`}
                >
                    {s.name}
                </Option>
                ))
            }
        </Select>
    } />;
    // #endregion

    // #region Projects
    const projectsFilter = <Filter title={company?.projectDisplayText ? getCaseAndPlural(company.projectDisplayText, true, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Projects'} />} input={
        <Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            // placeholder={<FormattedMessage defaultMessage={'Projects to display'} />}
            style={{ width: '100%' }}
            value={props.projects?.selectedProjects}
            onChange={props.projects?.changeProjects}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                projects.length > 0 && projects.map(p => (<Option
                    value={p.id}
                    key={"project-" + p.id}
                    label={`${p.title}`}
                >
                    {p.title}
                </Option>
                ))
            }
        </Select>
    } />;
    // #endregion

    // #region
    const eventClockedStatus = <Filter title={<FormattedMessage defaultMessage={'Event status'} />} input={
        <Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            // placeholder={<FormattedMessage defaultMessage={'Filters'} />}
            style={{ width: '100%' }}
            value={props.eventClockedStatus?.eventClockedStatus}
            onChange={props.eventClockedStatus?.changeEventClockedStatus}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                TimeClockControlFiltersOptions.map(p => (<Option
                    value={p.value}
                    key={"eventClockedStatus-" + p.value}
                    label={`${p.label}`}
                >
                    {p.label}
                </Option>
                ))
            }
        </Select>
    } />;
    // #endregion

    // #region Show empty
    const showEmptyFilter = <Filter input={
        <div>
            <Checkbox checked={!props.showEmpty?.showEmpty} onChange={(e) => props.showEmpty?.setShowEmpty(!e.target.checked)}>
                <FormattedMessage defaultMessage={'Hide users with no events'} />
            </Checkbox>
        </div>
    } />;
    // #endregion

    // #region Show type of day off
    const showTypeOfDayOffFilter = <Filter input={
        <div>
            <Checkbox checked={props.showDayOff?.showDayOff} onChange={(e) => props.showDayOff && props.showDayOff.setShowDayOff && props.showDayOff.setShowDayOff(e.target.checked)}>
                <FormattedMessage defaultMessage={'Ignore filters for day off events'} />
            </Checkbox>
        </div>
    } />;
    // #endregion


    return (
        <div className="filters">
            {
                hasUsersFilters ?
                    <>
                        <div className="main-title"><FAIcon prefix="far" name="user" /> <FormattedMessage defaultMessage={'Users'} /></div>
                        {
                            props.groups ?
                                <>
                                    {groupFilter}
                                    {props.groups.selectedGroups.length > 0 ? excludeFilter : null}
                                    <Divider />
                                </>
                                : null
                        }
                        {
                            props.users ?
                                <>
                                    {userFilter}
                                    <Divider />
                                </>
                                : null
                        }
                        {
                            props.showEmpty ?
                                showEmptyFilter
                                : null
                        }
                    </>
                    : null
            }
            {
                hasEventsFilters ?
                    <>
                        <div className="main-title"><FAIcon prefix="far" name="calendar" /> <FormattedMessage defaultMessage={'Events'} /></div>
                        {
                            props.typeOfDays ?
                                <>
                                    {typeOfDaysFilter}
                                    <Divider />
                                </>
                                : null
                        }
                        {
                            props.typeOfDaysOff ?
                                <>
                                    {typeOfDaysOffFilter}
                                    <Divider />
                                </>
                                : null
                        }
                        {
                            props.POIs ?
                                <>
                                    {POIsFilter}
                                    <Divider />
                                </>
                                : null
                        }
                        {
                            props.departments && !isNullOrEmpty(departments.data) ?
                                <>
                                    {departmentsFilter}
                                    <Divider />
                                </>
                                : null
                        }
                        {
                            props.projects && !isNullOrEmpty(projects) ?
                                <>
                                    {projectsFilter}
                                    <Divider />
                                </>
                                : null
                        }
                        {
                            props.staffTypes ?
                                <>
                                    {staffTypesFilter}
                                    <Divider />
                                </>
                                : null
                        }
                        {
                            props.showDayOff ?
                                <>
                                    {showTypeOfDayOffFilter}
                                    <Divider />
                                </>
                                : null
                        }
                        {
                            props.confirmed ?
                                <>
                                    {confirmedFilter}
                                    <Divider />
                                </>
                                : null
                        }
                    </>
                    : null

            }
            {
                hasBadgingFilters ?
                    <>
                        <div className="main-title"><Anticon><RiTimerFlashLine /></Anticon><FormattedMessage defaultMessage={'Timeclock'} /></div>
                        {
                            props.eventClockedStatus ?
                                <>
                                    {eventClockedStatus}
                                    <Divider />
                                </>
                                : null
                        }
                    </>
                    : null
            }

            <div className="reset-filters-button">
                <CircleButton
                    className='__monthly-planning-btn-filter'
                    small={true}
                    type={"default"}
                    icon={<FAIcon prefix="fal" name="rotate-left" color="dark" />}
                    onClick={props.reset}
                    title={intl.formatMessage({ defaultMessage: 'Reset' })} />
            </div>
        </div>
    );
};

const Filter = (props: { title?: ReactNode; input: ReactNode; extra?: ReactNode; }) => {
    return (
        <div className="filter-group">
            {props.title ? <div className="title">{props.title}</div> : <></>}
            <div className='filter-input'>
                {props.input}
                {props.extra}
            </div>
        </div>
    );
};

export default Filters;
import { NotificationApi } from "antd/lib/notification";
import moment from "moment";
import { EXTREMLY_LONG_RELOAD, FAST_RELOAD } from "../../utils/constants";
import Network from "../../utils/network";
import { AppVersion, Department, ISOCountry, StaffType, TypeOfContract, TypeOfVehicle } from "../../utils/types/generalTypes";
import { PlanningOccupancyRate, Project, TypeOfDay } from "../../utils/types/planningTypes";
import { ApplicationState, StoreAction, StoreDispatch } from "../../utils/types/storeTypes";
import { showNotification } from "../../utils/utils";
import { CHANGE_APP_VERSION, CHANGE_COUNTRIES, CHANGE_DEPARTMENTS, CHANGE_OCCUPANCY_RATES, CHANGE_PERMANENT_NOTIF_API, CHANGE_PROJECT, CHANGE_STAFFTYPES, CHANGE_TYPES_OF_CONTRACT, CHANGE_TYPES_OF_DAY, CHANGE_TYPES_OF_DAY_OFF, CHANGE_TYPES_OF_VEHICLES, LOADING_DEPARTMENTS, RESET } from "../reducer/configurations";


export const changeAppVersion = (appVersion: AppVersion): StoreAction => ({ type: CHANGE_APP_VERSION, data: appVersion });
export const setPermanentNotifApi = (api: NotificationApi): StoreAction => ({ type: CHANGE_PERMANENT_NOTIF_API, data: api });


/**
 * Change stored occupancy rates
 * @param occupancyRates the new occupancy rates to store
 * @return a store action
 */
export const changeOccupancyRates = (occupancyRates: PlanningOccupancyRate[]): StoreAction => ({ type: CHANGE_OCCUPANCY_RATES, data: occupancyRates });


/**
 * Change stored types of day
 * @param typesOfDay the new types of day to store
 * @return a store action
 */
export const changeTypesOfDay = (typesOfDay: TypeOfDay[]): StoreAction => ({ type: CHANGE_TYPES_OF_DAY, data: typesOfDay });

/**
 * Change stored types of day
 * @param typesOfDay the new types of day to store
 * @return a store action
 */
export const changeTypesOfDayOff = (typesOfDayOff: TypeOfDay[]): StoreAction => ({ type: CHANGE_TYPES_OF_DAY_OFF, data: typesOfDayOff });

/**
 * Change stored types of Contract
 * @param typesOfContract the new types of Contract to store
 * @return a store action
 */
export const changeTypesOfContract = (typesOfContract: TypeOfContract[], forceReload = false): StoreAction => ({ type: CHANGE_TYPES_OF_CONTRACT, data: typesOfContract, forceReload });

/**
 * Change stored types of Contract
 * @param typesOfContract the new types of Contract to store
 * @return a store action
 */
export const changeDepartments = (departments: Department[], forceReload = false): StoreAction => ({ type: CHANGE_DEPARTMENTS, data: departments, forceReload });
export const setLoadingDepartments = (loading=true): StoreAction => ({ type: LOADING_DEPARTMENTS,data: loading});


/**
 * Change stored staff types
 * @param typesOfContract the new types of Contract to store
 * @return a store action
 */
export const changeStaffTypes = (staffTypes: StaffType[], forceReload = false): StoreAction => ({ type: CHANGE_STAFFTYPES, data: staffTypes, forceReload });

/**
 * Change stored types of Contract
 * @param typesOfContract the new types of Contract to store
 * @return a store action
 */
export const changeTypesOfVehicles = (typesOfVehicles: TypeOfVehicle[], forceReload = false): StoreAction => ({ type: CHANGE_TYPES_OF_VEHICLES, data: typesOfVehicles, forceReload });

/**
 * Change stored projects
 * @param project the new projects to store
 * @return a store action
 */
export const changeProject = (project: Project[]): StoreAction => ({ type: CHANGE_PROJECT, data: project });

/**
 * Change stored types of Contract
 * @param typesOfContract the new types of Contract to store
 * @return a store action
 */
export const loadTypesOfContract = (forceReload = false) => (dispatch: StoreDispatch, getState: () => ApplicationState) => {
    const applicationState: ApplicationState = getState();
    if (applicationState.configurations.typesOfContract.updated === undefined || moment().diff(applicationState.configurations.typesOfContract.updated, "minutes") >= FAST_RELOAD || forceReload) {
        Network.getTypeOfContract().then(
            response => {
                dispatch(changeTypesOfContract(response, forceReload));
            },
            () => showNotification("Un problème est survenu pendant le chargement des types de contrats", "warning"),
        );
    }
};

/**
 * Change stored departments
 * @param departments the new types of Contract to store
 * @return a store action
 */
export const loadDepartments = (forceReload = false) => (dispatch: StoreDispatch, getState: () => ApplicationState) => {
    const applicationState: ApplicationState = getState();
    if (applicationState.configurations.departments.updated === undefined || moment().diff(applicationState.configurations.departments.updated, "minutes") >= FAST_RELOAD || forceReload) {
        dispatch(setLoadingDepartments());        
        Network.getDepartments().then(
            response => {
                dispatch(changeDepartments(response, forceReload));
            },
            () => {
                dispatch(setLoadingDepartments(false));  
                showNotification("Un problème est survenu pendant le chargement des types de contrats", "warning")
            },
        );
    }
};

/**
 * Change stored types of Vehicle type
 * @param typesOfContract the new types of Contract to store
 * @return a store action
 */
export const loadTypesOfVehicle = (forceReload = false) => (dispatch: StoreDispatch, getState: () => ApplicationState) => {
    const applicationState: ApplicationState = getState();
    if (applicationState.configurations.vehicles.updated === undefined || moment().diff(applicationState.configurations.vehicles.updated, "minutes") >= FAST_RELOAD || forceReload) {
        Network.getTypeOfVehicles().then(
            response => {
                dispatch(changeTypesOfVehicles(response, forceReload));
            },
            () => showNotification("Un problème est survenu pendant le chargement des types de véhocules", "warning"),
        );
    }
};


export const loadCountries = (forceReload = false) => (dispatch: StoreDispatch, getState: () => ApplicationState) => {
    const applicationState: ApplicationState = getState();
    if (applicationState.configurations.countries.updated === undefined || moment().diff(applicationState.configurations.countries.updated, "minutes") >= EXTREMLY_LONG_RELOAD || forceReload) {
        Network.getCountries().then(
            (response: { error: boolean, message: string, data: ISOCountry[]; }) => {
                if (response.error) {
                    showNotification("Un problème est survenu pendant le chargement des pays", "error");
                } else {
                    dispatch({ type: CHANGE_COUNTRIES, data: response.data });
                }
            },
            () => {
                showNotification("Un problème est survenu pendant le chargement des pays", "error");
            }
        );
    }
};

/**
 * Reset all stored values
 * @return a store action
 */
export const reset = () => ({ type: RESET });